import axios from 'axios';
import decode from 'jwt-decode';
import { LOGIN_TOKEN_KEY, REFRESH_TOKEN_KEY, STATION_TOKEN_KEY, TABLE_TOKEN_KEY } from '../common/config';
import { ProviderType } from '../common/permission';
import { appApi, appLogin } from '../endpoint';

export function clearLoginToken() {
  localStorage.removeItem(LOGIN_TOKEN_KEY);
}

export function setLoginToken(token) {
  localStorage.setItem(LOGIN_TOKEN_KEY, token);
}

export function setRefreshToken(token) {
  localStorage.setItem(REFRESH_TOKEN_KEY, token);
}

/**
 * Destroy user session and logout.
 */
export function logout() {
  localStorage.removeItem(LOGIN_TOKEN_KEY);
  console.log('authService.logout');
  clearLoginToken();
}

/**
 * Destroy user session and logout.
 */
export function logoutNotRedirect() {
  clearLoginToken();
}

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }

  const date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
}

export function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  console.log('expirationDate: ', expirationDate);
  return expirationDate < new Date();
}

export function getLoginToken() {
  return localStorage.getItem(LOGIN_TOKEN_KEY);
}

export function isLoggedIn() {
  const loginToken = getLoginToken();
  return !!loginToken && !isTokenExpired(loginToken);
}

export function requireAuth(nextState, replace) {
  if (!isLoggedIn()) {
    replace({ pathname: '/' });
  }
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function getLoginTokenData() {
  const token = localStorage.getItem(LOGIN_TOKEN_KEY);
  return token ? decode(token) : null;
}

export function getAuthUserId() {
  const token = localStorage.getItem(LOGIN_TOKEN_KEY);
  const dataToken = token ? decode(token) : null;
  return dataToken ? dataToken.sub : null;
}

export function getAuthUserKey() {
  const token = localStorage.getItem(LOGIN_TOKEN_KEY);
  const dataToken = token ? decode(token) : null;
  return dataToken ? dataToken.key : null;
}

export function getAuthUserEmail() {
  const token = localStorage.getItem(LOGIN_TOKEN_KEY);
  if (!token) {
    return null;
  }
  const dataToken = decode(token);
  return dataToken ? dataToken.email : null;
}

/**
 * Login a user.
 * @param payload
 * @returns {Promise.<executor|*|CancelToken>}
 */
export async function loginUser(payload) {
  axios.defaults.headers.common.Authorization = '';

  let path = '/api/v1/login';

  if (payload.provider === ProviderType.Google) {
    path = '/api/v1/login/google';
  }

  if (payload.provider === ProviderType.Facebook) {
    path = '/api/v1/login/facebook';
  }

  const response = await appLogin.post(path, payload);
  const { data } = response;

  const loginToken = data && data.token || "";
  const refreshToken = data && data.refreshToken || "";

  setLoginToken(loginToken);
  setRefreshToken(refreshToken);

  axios.defaults.headers.common.Authorization = `Bearer ${loginToken}`;
  appLogin.defaults.headers.common.Authorization = `Bearer ${loginToken}`;

  return data;
}

/**
 * Login a client.
 * @param payload
 * @returns {Promise.<executor|*|CancelToken>}
 */
export async function loginClient(payload) {
  axios.defaults.headers.common.Authorization = '';

  let path = '/api/v1/login/qr';

  const response = await appLogin.post(path, payload);
  const { data } = response;

  const loginToken = data && data.token || "";
  const refreshToken = data && data.refreshToken || "";

  setLoginToken(loginToken);
  setRefreshToken(refreshToken);

  axios.defaults.headers.common.Authorization = `Bearer ${loginToken}`;
  appLogin.defaults.headers.common.Authorization = `Bearer ${loginToken}`;

  return data;
}

export const isSessionExpired = () => {
  const loginToken = getLoginToken();
  return !!loginToken && isTokenExpired(loginToken);
};

export const refreshSessionToken = async () => {
  const token = getRefreshToken();
  axios.defaults.headers.common.Authorization = '';
  appLogin.defaults.headers.common.Authorization = '';
  const payload = {
    token: token,
  };
  const loginRequest = await appApi.post('/api/v1/refreshToken', payload).then((response) => response.data);
  const loginToken = loginRequest.token;
  const refreshToken = loginRequest.refreshToken;
  setLoginToken(loginToken);
  setRefreshToken(refreshToken);
  axios.defaults.headers.common.Authorization = `Bearer ${loginToken}`;
  appLogin.defaults.headers.common.Authorization = `Bearer ${loginToken}`;

  return loginToken;
};

export function getStationToken() {
  return localStorage.getItem(STATION_TOKEN_KEY);
}

export function clearStationToken() {
  localStorage.removeItem(STATION_TOKEN_KEY);
}

export function setStationToken(token) {
  localStorage.setItem(STATION_TOKEN_KEY, token);
}

export function getTableToken() {
  return localStorage.getItem(TABLE_TOKEN_KEY);
}

export function clearTableToken() {
  localStorage.removeItem(TABLE_TOKEN_KEY);
}

export function setTableToken(token) {
  localStorage.setItem(TABLE_TOKEN_KEY, token);
}
