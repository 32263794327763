import { appApi, appLogin, tableApi as tableContextApi } from '../endpoint';

export function getTable(id) {
  return appApi.get(`/api/v1/table/${id}`);
}

// export function createTable(payload) {
//   return appApi.post(`/api/v1/table`, payload);
// }

export function createTable(formData) {
  return appApi.post(`/api/v1/table`, formData);
}

export function updateTable(payload) {
  return appApi.put(`/api/v1/table`, payload);
}

export function listTable(filters) {
  return appApi.post(`/api/v1/table/list`, filters);
}

export function deleteTable(id) {
  return appApi.delete(`/api/v1/table/${id}`);
}

export function getAllTable() {
  return appApi.get(`/api/v1/table`);
}

export function validateTableUuid() {
  return tableContextApi.get(`/api/v1/validate/uuid`);
}

export function getTablePositions(uuid) {
  return appLogin.get(`/api/v1/table/positions/${uuid}`);
}

export function getTableUUIDQr(uuid) {
  return appApi.get(`/api/v1/table/uuid/qr/${uuid}`);
}

export function getTableUnassigned() {
  return appApi.get(`/api/v1/table/unassigned`);
}

export function closeTable(payload) {
  return appApi.put(`/api/v1/table/status`, payload);
}