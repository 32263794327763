import { useContext } from 'react';
import ThemeContext, { VIEWMODE } from '../../../contexts/themeContext';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import {
	clientMenu,
	dashboardPagesMenu,
	demoPagesMenu,
	employeeMenu,
	tableMenu
} from '../../../menu';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus, viewMode } = useContext(ThemeContext);

	const hideAside = viewMode === VIEWMODE.TABLET_TABLE;

	// if (hideAside) {
	// 	return null;
	// }

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				{(!viewMode || viewMode === VIEWMODE.DASHBOARD) &&
					<>
						<Navigation menu={dashboardPagesMenu} id='aside-dashboard' />
						<NavigationLine />
						<Navigation menu={demoPagesMenu} id='aside-demo-pages' />
					</>
				}
				{viewMode === VIEWMODE.TABLET_TABLE &&
					<Navigation menu={tableMenu} id='table-viewmode-pages' />
				}
				{viewMode === VIEWMODE.TABLET_EMPLOYEE &&
					<Navigation menu={employeeMenu} id='employe-viewmode-pages' />
				}
				{viewMode === VIEWMODE.CLIENT &&
					<Navigation menu={clientMenu} id='client-viewmode-pages' />
				}
			</AsideBody>
			<AsideFoot>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
