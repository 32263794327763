import decode from 'jwt-decode';
import PropTypes from 'prop-types';
import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setStationToken } from '../api/authService';
import * as securityApi from '../api/securityApi';
import { LOGIN_TOKEN_KEY, REFRESH_TOKEN_KEY, TABLET_KEY, USER_KEY } from '../common/config';
import ThemeContext from './themeContext';

export interface IUserProps {
	idUser: string;
	email: string;
	name?: string;
	lastname?: string;
	token: string;
	refreshToken: string;
}

export interface ITabletProps {
	id: number;
	idEmployee: number;
	idTable: number;
	idTabletType: number;
	idDeliveryCart: number;
	name: string;
	tabletType?: any;
	employee?: any;
	table?: any;
	deliveryCart?: any;
	stationKitchen?: any;
	authorizationToken: string;
}
export interface IAuthContextProps {
	user: string;
	setUser(...args: unknown[]): unknown;
	setLoginToken(...args: unknown[]): unknown;
	setRefreshToken(...args: unknown[]): unknown;
	setTabletData(...args: unknown[]): unknown;
	userData: Partial<IUserProps>;
	tabletData: Partial<ITabletProps>;
	logout(...args: unknown[]): unknown;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);
interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(localStorage.getItem(USER_KEY) || '');
	const [userData, setUserData] = useState<Partial<IUserProps>>({});

	const storedTablet = localStorage.getItem(TABLET_KEY);
	const [tabletData, setTabletData] = useState<Partial<ITabletProps>>(storedTablet && JSON.parse(storedTablet) || {});

	const [loginToken, setLoginToken] = useState<string>(localStorage.getItem(LOGIN_TOKEN_KEY) || '');
	const [refreshToken, setRefreshToken] = useState<string>(localStorage.getItem(REFRESH_TOKEN_KEY) || '');
	const { fullScreenStatus, setFullScreenStatus, viewMode } = useContext(ThemeContext);

	const navigate = useNavigate();

	useEffect(() => {
		localStorage.setItem(USER_KEY, user);
	}, [user]);

	useEffect(() => {
		localStorage.setItem(LOGIN_TOKEN_KEY, loginToken);
	}, [loginToken]);

	useEffect(() => {
		localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
	}, [refreshToken]);

	useEffect(() => {
		if (loginToken !== '') {
			setUserData(decode(loginToken) || {});
		} else {
			setUserData({});
		}
	}, [loginToken]);

	useEffect(() => {
		localStorage.setItem(TABLET_KEY, JSON.stringify(tabletData));
		setStationToken(tabletData.authorizationToken)

	}, [tabletData]);

	const logout = async () => {
		try {
			await securityApi.logout();

			if (fullScreenStatus) setFullScreenStatus(false);

		} catch (error: any) {
			console.log('error', error);
		}
		finally {
			localStorage.clear();
			navigate(`../auth-pages/login`);
		}
	}

	const value = useMemo(
		() => ({
			user,
			setUser,
			setUserData,
			setTabletData,
			setLoginToken,
			setRefreshToken,
			userData,
			tabletData,
			logout,
		}),
		[user, userData, tabletData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
