const WAITING_TOKEN_KEY = 'waiting_token'

const isValidBoolean = (value: any) => {
    return typeof value === 'boolean';
};

export const setWaitingToken = (waitingToken: boolean) => {
    if (!isValidBoolean(waitingToken)) {
        console.log('>>>>: src/helpers/storageUtils.js : setWaitingToken -> invalid boolean received');
        waitingToken = false;
    }
    localStorage.setItem(WAITING_TOKEN_KEY, waitingToken.toString());
};

export const isWaitingToken = () => {
    try {
        return localStorage.getItem(WAITING_TOKEN_KEY) === 'true';
    } catch (error) {
        console.log('>>>>: src/helpers/storageUtils.js : getWaitingToken -> error', error);
        return false;
    }
};