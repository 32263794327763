import packageJson from '../../package.json';

export const API_BASE = process.env.REACT_APP_API_BASE || 'http://localhost:5000';

export const GOOGLE_OAUTH_ENABLED = (process.env.REACT_APP_GOOGLE_OAUTH_ENABLED || '') === 'true';
export const GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || '';

export const FACEBOOK_OAUTH_ENABLED = (process.env.REACT_APP_FACEBOOK_OAUTH_ENABLED || '') === 'true';
export const FACEBOOK_OAUTH_CLIENT_ID = process.env.REACT_APP_FACEBOOK_OAUTH_CLIENT_ID || '';

export const MP_URL = process.env.REACT_APP_MP_URL || 'https://sdk.mercadopago.com/js/v2';
export const MP_KEY = process.env.REACT_APP_MP_KEY || '';

export const TABLET_KEY = 'alamesa_tablet';
export const VIEWMODE_KEY = 'alamesa_viewMode';
export const USER_KEY = 'alamesa_user';
export const USE_COOKIE_KEY = 'alamesa_usecookie';
export const ID_EMPLOYEE_KEY = 'alamesa_idEmployee';
export const LOGIN_TOKEN_KEY = 'alamesa_loginToken';
export const REFRESH_TOKEN_KEY = 'alamesa_refreshToken';
export const TABLE_TOKEN_KEY = 'alamesa_tableToken';
export const STATION_TOKEN_KEY = 'alamesa_stationToken';
export const UNAUTHORIZED_ERROR_CODE = 403;

export const GOOGLE_RECAPTCHA_SITE_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY || '6LcHXesaAAAAAKtTG0HMeiGxt_n4ZLarXRpBQyB6';
export const APP_IDLE_TIMEOUT = process.env.REACT_APP_IDLE_TIMEOUT || 60;
export const APP_IDLE_TIMEOUT_ALERT = (1000 * 60 * Number(APP_IDLE_TIMEOUT)) - 30000; //30s before session expire

export const AUTHENTICATION_REQUIRED = 511;

export const getVersion = () => { return packageJson.version; }