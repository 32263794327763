import { useTour } from '@reactour/tour';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FC, ReactNode, useContext, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Badge from '../../../components/bootstrap/Badge';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle
} from '../../../components/bootstrap/Dropdown';
import Popovers from '../../../components/bootstrap/Popovers';
import Spinner from '../../../components/bootstrap/Spinner';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import OrderCartContext from '../../../contexts/orderCartContext';
import ThemeContext, { VIEWMODE } from '../../../contexts/themeContext';
import { priceFormat } from '../../../helpers/helpers';
import useDarkMode from '../../../hooks/useDarkMode';
import useModal from '../../../hooks/useModal';
import LANG, { ILang, getLangWithKey } from '../../../lang';
import { HeaderRight } from '../../../layout/Header/Header';
import Cart from './cart';

interface ICommonHeaderRightProps {
	beforeChildren?: ReactNode;
	afterChildren?: ReactNode;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({ beforeChildren, afterChildren }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const { fullScreenStatus, setFullScreenStatus, viewMode } = useContext(ThemeContext);
	const { total } = useContext(OrderCartContext);
	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const { i18n } = useTranslation();

	const changeLanguage = (lng: ILang['key']['lng']) => {
		i18n.changeLanguage(lng).then();
		showNotification(
			<span className='d-flex align-items-center'>
				<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
				<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
			</span>,
			'You updated the language of the site.',
		);
	};

	const { state: stateModalCart, open: openModalCart, setIsOpen: setIsOpenCart } = useModal();
	// const [menu, loadingMenu] = useFetch({ requestData: isValidToken ? menuApi.getMenuFromTableContext : null, deps: [isValidToken], initialValue: { data: {} } });


	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
	});

	const { setIsOpen } = useTour();

	const getCurrencyFormat = (value: number | string, currencySymbol = '$') => {
		// @ts-ignore
		const truncated = String(value).match(/^-?\d+(?:\.\d{0,2})?/)[0];
		return `${currencySymbol} ${truncated}`;
	}

	return (
		<>
			<HeaderRight>
				<div className='row g-3'>
					{beforeChildren}

					{/* Dark Mode */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Dark / Light mode'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								onClick={() => setDarkModeStatus(!darkModeStatus)}
								className='btn-only-icon'
								data-tour='dark-mode'>
								<Icon
									icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
									color={darkModeStatus ? 'info' : 'warning'}
									className='btn-icon'
								/>
							</Button>
						</Popovers>
					</div>

					{/*	Full Screen */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Fullscreen'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
								onClick={() => setFullScreenStatus(!fullScreenStatus)}
								aria-label='Toggle dark mode'
							/>
						</Popovers>
					</div>

					{/* Cart */}
					{(viewMode === VIEWMODE.TABLET_TABLE || viewMode === VIEWMODE.CLIENT) &&
						<div className='col-auto'>
							<Dropdown>
								<DropdownToggle hasIcon={false}>
									{/* eslint-disable-next-line react/jsx-props-no-spreading */}
									<Button {...styledBtn} icon='ShoppingCart' aria-label='Cart'
										onClick={() => {
											openModalCart(1);
										}}
									>
										<Badge isLight color='primary'>
											{priceFormat(total)}
										</Badge>
									</Button>
								</DropdownToggle>
								<DropdownMenu isAlignmentEnd size='md' className='py-0 overflow-hidden'>
									<div className='row g-0'>
										<div
											className={classNames(
												'col-12',
												'p-2',
												'd-flex justify-content-center',
												'fw-bold fs-5',
												'text-info',
												'border-bottom border-info',
												{
													'bg-l25-info': !darkModeStatus,
													'bg-lo25-info': darkModeStatus,
												},
											)}>
											Cart
										</div>
									</div>
								</DropdownMenu>
							</Dropdown>
						</div>
					}

					{/* Lang Selector */}
					<div className='col-auto'>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								{typeof getLangWithKey(i18n.language as ILang['key']['lng'])?.icon ===
									'undefined' ? (
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										className='btn-only-icon'
										aria-label='Change language'
										data-tour='lang-selector'>
										<Spinner isSmall inButton='onlyIcon' isGrow />
									</Button>
								) : (
									<Button
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...styledBtn}
										icon={
											getLangWithKey(i18n.language as ILang['key']['lng'])?.icon
										}
										aria-label='Change language'
										data-tour='lang-selector'
									/>
								)}
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
								{Object.keys(LANG).map((i) => (
									<DropdownItem key={LANG[i].lng}>
										<Button
											icon={LANG[i].icon}
											onClick={() => changeLanguage(LANG[i].lng)}>
											{LANG[i].text}
										</Button>
									</DropdownItem>
								))}
							</DropdownMenu>
						</Dropdown>
					</div>
					{afterChildren}
				</div>
			</HeaderRight>
			<Cart
				isOpen={stateModalCart.isOpen}
				setIsOpen={setIsOpenCart}
				data={stateModalCart.data}
			// onSuccessSubmit={() => { fetchData({ filters }); }}
			/>

			{/* <ScreenLoader show={loadingMenu} /> */}
		</>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
