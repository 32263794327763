import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FC } from 'react';
import Spinner from './bootstrap/Spinner';

interface IScreenLoader {
	show?: boolean;
}
const ScreenLoader: FC<IScreenLoader> = ({ show }) => {
	return (
		<>
			{show &&
				<div
					className={classNames('loading-overlay fade', { 'show': show })}
				>
					<Spinner className='loading' />
				</div>
			}
		</>
	);
};

ScreenLoader.propTypes = {
	show: PropTypes.bool,
};
ScreenLoader.defaultProps = {
	show: false,
};

export default ScreenLoader;
