import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as tableApi from '../../src/api/tableApi';
import * as orderApi from '../api/orderApi';
import { OrderStatusType } from '../common/orderStatusType';
import showNotification from '../components/extras/showNotification';
import Icon from '../components/icon/Icon';
import * as swal from '../helpers/swal';
import useDarkMode from '../hooks/useDarkMode';

export interface IOrderStock {
	idStock: any,
	quantity: number,
	stock?: any,
	tablePosition?: number,
}

export interface ICardProps {
	id?: number;
	idOrderStatusType?: number;
	orderStocks: Partial<IOrderStock>[];
	orderStatusType?: any;
	paymentInitPoint?: string;
	orderStatusPosition?: [];
}

export interface IOrder {
	id?: number | null,
	idOrderStatusType?: number,
	idTable: number,
	orderStatusPosition: unknown[],
	orderStatusType: number | null,
	orderStocks: Partial<IOrderStock>[];
	paymentInitPoint: string | null,
	amount: number,
}

export interface IOrderCartContextProps {
	cartData: Partial<ICardProps>;
	tablePosition: number;
	idTable: number;
	total: number;
	setCartData(...args: unknown[]): unknown;
	addProduct(...args: unknown[]): unknown;
	deleteProduct(...args: unknown[]): unknown;
	setQuantity(...args: unknown[]): unknown;
	setTablePosition(...args: unknown[]): unknown;
	setIdTable(...args: unknown[]): unknown;

	currentOrder: IOrder;
	qrPositions: unknown[];
	loadingCurrentOrder: boolean;
	loadingQrPositions: boolean;
	updating: boolean;
	cancelingOrder: boolean;
	confirmingOrder: boolean;
	verifyingPayment: boolean;

	uuid: string,
	setUuid(...args: unknown[]): unknown;

	load(...args: unknown[]): unknown;
	loadCurrentOrder(...args: unknown[]): unknown;
	loadQrPositions(...args: unknown[]): unknown;

	cancelPositionOrder(...args: unknown[]): unknown;
	acceptPositionOrder(...args: unknown[]): unknown;
	editPositionOrder(...args: unknown[]): unknown;

	getOrderStatusPosition(...args: unknown[]): unknown;
	getOrderStocksPosition(...args: unknown[]): unknown;
	getSubtotalPosition(...args: unknown[]): unknown;
	getTotalPrice(...args: unknown[]): number;
	getPositionBgClassname(...args: unknown[]): string;
	getNamePosition(...args: unknown[]): string;

	cancelOrder(...args: unknown[]): unknown;
	confirmOrder(...args: unknown[]): unknown;
	verifyPayment(...args: unknown[]): unknown;
}
const OrderCartContext = createContext<IOrderCartContextProps>({} as IOrderCartContextProps);
interface IOrderCartContextProviderProps {
	children: ReactNode;
}
export const OrderCartContextProvider: FC<IOrderCartContextProviderProps> = ({ children }) => {

	const { themeStatus, darkModeStatus } = useDarkMode();
	const { t } = useTranslation(['menu', 'translation']);

	const [currentOrder, setCurrentOrder] = useState<IOrder>();
	const [loadingCurrentOrder, setLoadingCurrentOrder] = useState(false);
	const [currentOrderError, setCurrentOrderError] = useState<unknown>(null);

	const [qrPositions, setQrPositions] = useState<any>([]);
	const [loadingQrPositions, setLoadingQrPositions] = useState(false);
	const [loadingQrPositionsError, setLoadingQrPositionsError] = useState<unknown>(null);

	const [uuid, setUuid] = useState(localStorage.getItem('alamesa_uuid') || "");
	const [tablePosition, setTablePosition] = useState(Number(localStorage.getItem('alamesa_tablePosition') || 'A'));
	const [idTable, setIdTable] = useState(Number(localStorage.getItem('alamesa_idTable') || 0));

	const [updating, setUpdating] = useState(false);
	const [cancelingOrder, setCancelingOrder] = useState(false);
	const [confirmingOrder, setConfirmingOrder] = useState(false);
	const [verifyingPayment, setVerifyingPayment] = useState(false);

	const [total, setTotal] = useState(0);

	useEffect(() => {
		localStorage.setItem('alamesa_uuid', String(uuid));
	}, [uuid]);

	useEffect(() => {
		localStorage.setItem('alamesa_idTable', String(idTable));
	}, [idTable]);

	useEffect(() => {
		localStorage.setItem('alamesa_tablePosition', String(tablePosition));
	}, [tablePosition]);

	useEffect(() => {
		setTotal(getTotalPrice());
	}, [currentOrder]);

	const loadCurrentOrder = useCallback(async () => {
		setCurrentOrderError(null);
		setCurrentOrderError(null);
		try {
			setLoadingCurrentOrder(true);
			const response = await orderApi.getCurrentOrder();
			setCurrentOrder(response.data);
			setLoadingCurrentOrder(false);
		} catch (e) {
			setLoadingCurrentOrder(false);
			setCurrentOrderError(e);
		}
	}, []);

	const loadQrPositions = useCallback(async () => {
		setQrPositions([]);
		setLoadingQrPositionsError(null);

		if (!uuid) {
			return;
		}
		try {
			setLoadingQrPositions(true);
			const response = await tableApi.getTablePositions(uuid);
			setQrPositions(response.data);
			setLoadingQrPositions(false);
		} catch (e) {
			setLoadingQrPositions(false);
			setLoadingQrPositionsError(e);
		}
	}, [uuid]);

	const load = useCallback(async () => {
		await loadCurrentOrder();
		await loadQrPositions();
	}, [loadCurrentOrder, loadQrPositions]);

	const getOrderStatusPosition = (position: number) => {
		return (currentOrder?.orderStatusPosition || []).find((item: any) => item.position === position);
	}

	const getOrderStocksPosition = (position: number) => {
		return (currentOrder?.orderStocks || []).filter((item: any) => item.tablePosition === position);
	}

	const getPositionBgClassname = (position: number) => {
		const statusPosition = getOrderStatusPosition(position) as any;
		if (!statusPosition) {
			return '';
		}

		if (statusPosition.finished) {
			return 'text-white bg-success';
		} else {
			return getOrderStocksPosition(position).length > 0 ? 'text-white bg-warning' : '';
		}
	}


	const getNamePosition = (position: number) => {
		const statusPosition = getOrderStatusPosition(position) as any;

		if (!statusPosition) {
			return t("translation:cart.position.name.empty");
		}

		return statusPosition.dinnerName || t("translation:cart.position.name.empty");
	}

	const getSubtotalPosition = (position: number) => {
		return getOrderStocksPosition(position).reduce((total: any, item: any) => {
			const subtotal = item.quantity * item.stock.amount;
			return total + subtotal;
		}, 0);
	}

	const getTotalPrice = () => {
		return (currentOrder?.orderStocks || []).reduce((total: any, item: any) => {
			const subtotal = item.quantity * item.stock?.amount || 0;
			return total + subtotal;
		}, 0);
	}

	const getPayloadOrder = (data = currentOrder) => {
		data = cloneDeep(data);

		return {
			idOrderStatusType: OrderStatusType.OrderStatusStarted,
			orderStocks: [],
			orderStatusType: null,
		}
	}

	const addProduct = async (stock: any) => {

		if (currentOrder?.idOrderStatusType === OrderStatusType.OrderStatusReadyToPay) {
			showNotification(
				t('translation:general.operation.fail-title') as string,
				t('translation:cart.orderPending') as string,
			);
			return;
		}

		const status: any = getOrderStatusPosition(tablePosition);
		if (status && status.finished === true) {
			showNotification(
				t('translation:general.operation.fail-title') as string,
				`El pedido correspondiente a la posición ${tablePosition} esta finalizado. Debe editarlo para poder agregar nuevos productos`,
			);
			return;
		}

		try {

			setUpdating(true);

			const copy = cloneDeep(currentOrder);
			let orderStocks = copy?.orderStocks || [];
			let orderStatusPosition = copy?.orderStatusPosition || [];

			const index = (orderStocks || []).findIndex((item: any) => item.idStock === stock.id && item.tablePosition === tablePosition);

			if (index === -1) {
				orderStocks.push({
					idStock: stock.id,
					quantity: 1,
					tablePosition: tablePosition,
				})
			} else {
				orderStocks[index].quantity = Number(orderStocks[index].quantity) + 1;
			}

			// // @ts-ignore
			// orderStatusPosition = (orderStatusPosition || []).reduce((acc: any, item: any) => {
			// 	if (!acc.some((x: any) => x.position === item.position)) {
			// 		const { id, idOrder, ...data } = item;
			// 		acc.push({ ...data });
			// 	}
			// 	return acc;
			// }, []);

			const payload = {
				// idOrderStatusType: OrderStatusType.OrderStatusStarted,
				idOrderStatusType: currentOrder?.idOrderStatusType,
				orderStocks: orderStocks,
				// orderStocks: (orderStocks || []).map((item: any) => ({
				// 	idStock: item.idStock,
				// 	quantity: item.quantity,
				// 	tablePosition: item.tablePosition,
				// })),
				orderStatusPosition: orderStatusPosition,
			};

			await orderApi.upsertCurrentOrder(payload);

			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='ShoppingCart' size='lg' className='me-1' />
					<span>{t('translation:tabletMenu.addProductSuccessTitle') as string}</span>
				</span>,
				t('translation:tabletMenu.addProductSuccess', { value: stock.name }) as string,
			);

		} catch (error: any) {
			console.log('error', error)
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='ShoppingCart' size='lg' className='me-1' />
					<span>{t('translation:general.operation.fail-title') as string}</span>
				</span>,
				t('translation:general.operation.fail', { value: stock.name }) as string,
			);
		}
		finally {
			setUpdating(false);
			await loadCurrentOrder();
		}
	}

	const deleteProduct = async (position: number, idStock: number) => {
		try {

			setUpdating(true);

			const copy = cloneDeep(currentOrder);
			let orderStocks = copy?.orderStocks || [];
			let orderStatusPosition = copy?.orderStatusPosition || [];

			// // @ts-ignore
			// orderStatusPosition = (orderStatusPosition || []).reduce((acc: any, item: any) => {
			// 	if (!acc.some((x: any) => x.position === item.position)) {
			// 		const { id, idOrder, ...data } = item;
			// 		acc.push({ ...data });
			// 	}
			// 	return acc;
			// }, []);

			const payload = {
				// idOrderStatusType: OrderStatusType.OrderStatusStarted,
				idOrderStatusType: currentOrder?.idOrderStatusType,
				orderStocks: (orderStocks || []).filter((item: any) => !(item.tablePosition === position && item.idStock === idStock)),
				orderStatusPosition: orderStatusPosition,
			};

			await orderApi.upsertCurrentOrder(payload);

		} catch (error: any) {
			console.log('error', error)
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='ShoppingCart' size='lg' className='me-1' />
					<span>{t('translation:general.operation.fail-title') as string}</span>
				</span>,
				t('translation:general.operation.fail') as string,
			);
		}
		finally {
			setUpdating(false);
			await loadCurrentOrder();
		}
	}

	const setQuantity = async (position: number, idStock: number, quantity: number) => {
		try {

			setUpdating(true);

			const copy = cloneDeep(currentOrder);
			let orderStocks = copy?.orderStocks || [];
			let orderStatusPosition = copy?.orderStatusPosition || [];

			const index = orderStocks.findIndex((item: any) => item.tablePosition === position && item.idStock === idStock);

			if (index === -1) {
				orderStocks.push({
					tablePosition: position,
					idStock: idStock,
					quantity: quantity,
				});
			} else {
				orderStocks[index].quantity = quantity;
			}

			// // @ts-ignore
			// orderStatusPosition = (orderStatusPosition || []).reduce((acc: any, item: any) => {
			// 	if (!acc.some((x: any) => x.position === item.position)) {
			// 		const { id, idOrder, ...data } = item;
			// 		acc.push({ ...data });
			// 	}
			// 	return acc;
			// }, []);

			const payload = {
				idOrderStatusType: OrderStatusType.OrderStatusStarted,
				orderStocks: (orderStocks || []).map((item: any) => ({
					tablePosition: item.tablePosition,
					idStock: item.idStock,
					quantity: item.quantity,
				})),
				orderStatusPosition: orderStatusPosition,
			};

			await orderApi.upsertCurrentOrder(payload);

		} catch (error: any) {
			console.log('error', error)
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='ShoppingCart' size='lg' className='me-1' />
					<span>{t('translation:general.operation.fail-title') as string}</span>
				</span>,
				t('translation:general.operation.fail') as string,
			);
		}
		finally {
			setUpdating(false);
			await loadCurrentOrder();
		}
	}

	const cancelPositionOrder = async (position: number) => {

		swal.confirm({
			// @ts-ignore
			onConfirm: async () => {
				try {

					if (currentOrder?.idOrderStatusType === OrderStatusType.OrderStatusReadyToPay) {
						showNotification(
							t('translation:general.operation.fail-title') as string,
							t('translation:cart.orderPending') as string,
						);
						return;
					}

					setUpdating(true);

					const orderStocks = (currentOrder?.orderStocks || [])
						.filter((item: any) => item.tablePosition !== position);

					const orderStatusPosition = cloneDeep((currentOrder?.orderStatusPosition || []))
						.map((item: any) => {
							if (item.position === position) {
								item.finished = false;
							}
							return item;
						});

					const payload = {
						idOrderStatusType: OrderStatusType.OrderStatusStarted,
						orderStocks: orderStocks.filter(item => item.tablePosition !== 0),
						orderStatusPosition: orderStatusPosition,
					};

					await orderApi.upsertCurrentOrder(payload);

					showNotification(
						t('translation:general.operation.success-title') as string,
						t('translation:general.operation.success') as string,
					);

				} catch (error: any) {

					const messageKey = error.response && error.response.data && error.response.data.message;
					const message = t(`translation:${messageKey}`, t('translation:general.operation.fail')) as string;
					showNotification(
						t('translation:general.operation.fail-title') as string,
						message,
					);

				}
				finally {
					setUpdating(false);
					loadCurrentOrder();
				}
			},
		});
	}

	const acceptPositionOrder = async (position: number) => {
		try {
			if (currentOrder?.idOrderStatusType === OrderStatusType.OrderStatusReadyToPay) {
				showNotification(
					t('translation:general.operation.fail-title') as string,
					t('translation:cart.orderPending') as string,
				);
				return;
			}

			if (currentOrder?.orderStocks.length == 0) {
				showNotification(
					t('translation:general.operation.fail-title') as string,
					t('translation:cart.empty') as string,
				);
				return;
			}

			setUpdating(true);

			const orderStatusPosition = cloneDeep((currentOrder?.orderStatusPosition || [])).map((item: any) => {
				if (item.position === position) {
					item.finished = true;
				}
				return item;
			})

			const payload = {
				idOrderStatusType: OrderStatusType.OrderStatusStarted,
				orderStocks: currentOrder?.orderStocks,
				orderStatusPosition: orderStatusPosition,
			};

			await orderApi.upsertCurrentOrder(payload);

			swal.confirm({
				title: t('translation:general.operation.info-title') as string,
				text: t('translation:order.position.confirm') as string,
				confirmButtonText: t('translation:general.buttons.close') as string,
				showCancelButton: false
			});
			// showNotification(
			// 	<span className='d-flex align-items-center'>
			// 		<Icon icon='ShoppingCart' size='lg' className='me-1' />
			// 		<span>{t('translation:tabletMenu.addProductSuccessTitle') as string}</span>
			// 	</span>,
			// 	t('translation:tabletMenu.addProductSuccess', { value: stock.name }) as string,
			// );
		} catch (error: any) {
			// showNotification(
			// 	<span className='d-flex align-items-center'>
			// 		<Icon icon='ShoppingCart' size='lg' className='me-1' />
			// 		<span>{t('translation:general.operation.fail-title') as string}</span>
			// 	</span>,
			// 	t('translation:general.operation.fail', { value: stock.name }) as string,
			// );
		}
		finally {
			setUpdating(false);
			loadCurrentOrder();
		}
	}

	const editPositionOrder = async (position: number) => {
		try {

			if (currentOrder?.idOrderStatusType === OrderStatusType.OrderStatusReadyToPay) {
				showNotification(
					t('translation:general.operation.fail-title') as string,
					t('translation:cart.orderPending') as string,
				);
				return;
			}

			setUpdating(true);

			const orderStatusPosition = cloneDeep((currentOrder?.orderStatusPosition || [])).map((item: any) => {
				if (item.position === position) {
					item.finished = false;
				}
				return item;
			})

			const payload = {
				idOrderStatusType: OrderStatusType.OrderStatusStarted,
				orderStocks: currentOrder?.orderStocks,
				orderStatusPosition: orderStatusPosition,
			};

			await orderApi.upsertCurrentOrder(payload);

			// showNotification(
			// 	<span className='d-flex align-items-center'>
			// 		<Icon icon='ShoppingCart' size='lg' className='me-1' />
			// 		<span>{t('translation:tabletMenu.addProductSuccessTitle') as string}</span>
			// 	</span>,
			// 	t('translation:tabletMenu.addProductSuccess', { value: stock.name }) as string,
			// );
		} catch (error: any) {
			// showNotification(
			// 	<span className='d-flex align-items-center'>
			// 		<Icon icon='ShoppingCart' size='lg' className='me-1' />
			// 		<span>{t('translation:general.operation.fail-title') as string}</span>
			// 	</span>,
			// 	t('translation:general.operation.fail', { value: stock.name }) as string,
			// );
		}
		finally {
			setUpdating(false);
			loadCurrentOrder();
		}
	}

	const cancelOrder = async () => {

		swal.confirm({
			// @ts-ignore
			onConfirm: async () => {
				try {

					setCancelingOrder(true);

					if (currentOrder?.id) {
						await orderApi.cancelCurrentOrder();
					}

					// @ts-ignore
					setCurrentOrder({
						id: null,
						idOrderStatusType: OrderStatusType.OrderStatusStarted,
						orderStocks: [],
						orderStatusType: null,
						paymentInitPoint: null,
					});

					showNotification(
						t('translation:general.operation.success-title') as string,
						t('translation:general.operation.success') as string,
					);

				} catch (error: any) {

					const messageKey = error.response && error.response.data && error.response.data.message;
					const message = t(`translation:${messageKey}`, t('translation:general.operation.fail')) as string;
					showNotification(
						t('translation:general.operation.fail-title') as string,
						message,
					);

				}
				finally {
					setCancelingOrder(false);
					await loadCurrentOrder();
				}
			},
		});
	}

	const confirmOrder = async () => {
		try {

			if (!currentOrder?.orderStocks || !currentOrder?.orderStocks.length) {
				showNotification(
					t('translation:general.operation.fail-title') as string,
					t('translation:cart.emptyProducts') as string,
				);
				return;
			}

			setConfirmingOrder(true);

			const orderStatusPosition = cloneDeep((currentOrder.orderStatusPosition || [])).reduce((acc: any, item: any) => {
				if (!acc.some((x: any) => x.position === item.position)) {
					const { id, idOrder, ...data } = item;
					acc.push({ ...data });
				}
				return acc;
			}, []);

			const payload = {
				idOrderStatusType: OrderStatusType.OrderStatusFinished,
				orderStocks: currentOrder.orderStocks,
				orderStatusPosition: orderStatusPosition,
			};

			const response = await orderApi.upsertCurrentOrder(payload);
			await loadCurrentOrder();
			//   const { id, idOrderStatusType, orderStatusType, orderStocks, paymentInitPoint } = response.data;

			//   setCartData((prev: any) => ({
			// 	...prev,
			// 	id: id || null,
			// 	idOrderStatusType: idOrderStatusType || null,
			// 	orderStatusType: orderStatusType || null,
			// 	orderStocks: orderStocks || [],
			// 	paymentInitPoint: paymentInitPoint || null,
			//   }))

		} catch (error: any) {

			const messageKey = error.response && error.response.data && error.response.data.message;
			const message = t(`translation:${messageKey}`, t('translation:general.operation.fail')) as string;
			showNotification(
				t('translation:general.operation.fail-title') as string,
				message,
			);

		}
		finally {
			setConfirmingOrder(false);
		}
	}

	const verifyPayment = async () => {
		try {
			setVerifyingPayment(true);

			const payload = {
				paymentUrl: currentOrder?.paymentInitPoint,
			};

			const response = await orderApi.verifyPayment(payload);
			const { completed } = response.data;

			if (completed) {
				showNotification(
					t('translation:general.operation.empty-title') as string,
					t('translation:cart.paymentSuccess') as string,
				);

				// @ts-ignore
				setCurrentOrder({
					id: null,
					idOrderStatusType: OrderStatusType.OrderStatusStarted,
					orderStocks: [],
					orderStatusType: null,
					paymentInitPoint: null,
				});

				// setIsOpen(false);
				swal.confirm({
					title: t('translation:general.operation.info-title') as string,
					text: t('translation:cart.paymentSuccess') as string,
					confirmButtonText: t('translation:general.buttons.close') as string,
					showCancelButton: false
				});

			} else {
				showNotification(
					t('translation:general.operation.empty-title') as string,
					t('translation:cart.paymentPending') as string,
				);
			}

		} catch (error: any) {

			const messageKey = error.response && error.response.data && error.response.data.message;
			const message = t(`translation:${messageKey}`, t('translation:general.operation.fail')) as string;
			showNotification(
				t('translation:general.operation.fail-title') as string,
				message,
			);

		}
		finally {
			setVerifyingPayment(false);
		}
	}

	const value = useMemo(
		() => ({
			tablePosition,
			idTable,
			addProduct,
			deleteProduct,
			setQuantity,
			setTablePosition,
			setIdTable,

			uuid,
			setUuid,

			currentOrder,
			loadingCurrentOrder,
			updating,
			cancelingOrder,
			confirmingOrder,
			verifyingPayment,

			qrPositions,
			loadingQrPositions,
			total,

			load,
			loadCurrentOrder,
			loadQrPositions,

			cancelPositionOrder,
			acceptPositionOrder,
			editPositionOrder,

			getOrderStatusPosition,
			getOrderStocksPosition,
			getSubtotalPosition,
			getTotalPrice,
			getPositionBgClassname,
			getNamePosition,

			cancelOrder,
			confirmOrder,
			verifyPayment,
		}),
		[
			tablePosition,
			addProduct,
			deleteProduct,
			setQuantity,
			setTablePosition,
			setIdTable,

			uuid,
			setUuid,

			currentOrder,
			loadingCurrentOrder,
			updating,
			cancelingOrder,
			confirmingOrder,
			verifyingPayment,

			qrPositions,
			loadingQrPositions,
			total,

			load,
			loadCurrentOrder,
			loadQrPositions,

			cancelPositionOrder,
			acceptPositionOrder,
			editPositionOrder,

			getOrderStatusPosition,
			getOrderStocksPosition,
			getSubtotalPosition,
			getTotalPrice,
			getPositionBgClassname,
			getNamePosition,

			cancelOrder,
			confirmOrder,
			verifyPayment,
		],
	);
	// @ts-ignore
	return <OrderCartContext.Provider value={value}>{children}</OrderCartContext.Provider>;
};
OrderCartContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default OrderCartContext;
