import { lazy } from 'react';
import { RouteProps } from 'react-router-dom';
import {
	cartMenu,
	cartSalonMenu,
	clientMenu,
	dashboardPagesMenu,
	demoPagesMenu, employeeMenu, stationMenu, tableMenu
} from '../menu';
import Login from '../pages/auth/Login';
import Menu from '../pages/public/Menu';

const AUTH = {
	PAGE_404: lazy(() => import('../pages/auth/Page404')),
};

const APP = {
	HOME: {
		LIST: lazy(
			() => import('../pages/app/dashboard/DashboardPage'),
		),
	},
	AUTISM_CLASSIFICATION: {
		LIST: lazy(
			() => import('../pages/app/autismClassification/List'),
		),
	},
	CATEGORY: {
		LIST: lazy(
			() => import('../pages/app/category/List'),
		),
	},
	SUBCATEGORY: {
		LIST: lazy(
			() => import('../pages/app/subcategory/List'),
		),
	},
	CART: {
		LIST: lazy(
			() => import('../pages/app/cart/List'),
		),
		ADD: lazy(
			() => import('../pages/app/cart/AddCart'),
		),
		EDIT: lazy(
			() => import('../pages/app/cart/EditCart'),
		),
		SHOW: lazy(
			() => import('../pages/app/cart/ShowCart'),
		),
	},
	STATION: {
		LIST: lazy(
			() => import('../pages/app/stationKitchen/List'),
		),
		CONFIGURESCREEN: lazy(
			() => import('../pages/app/stationKitchen/ConfigureScreen'),
		),
		COMMON: lazy(
			() => import('../pages/app/stationKitchen/screen/CommonStation'),
		),
		RATIONAL: lazy(
			() => import('../pages/app/stationKitchen/screen/RationalStation'),
		),
		FOOD: lazy(
			() => import('../pages/app/stationKitchen/screen/cart/CartFoodStation'),
		),
		WINE: lazy(
			() => import('../pages/app/stationKitchen/screen/drink/Wine'),
		),
		WINEDETAILS: lazy(
			() => import('../pages/app/stationKitchen/screen/drink/DetailWine'),
		),
		DRINKS: lazy(
			() => import('../pages/app/stationKitchen/screen/drink/Drinks'),
		),
		DRINKDETAILS: lazy(
			() => import('../pages/app/stationKitchen/screen/drink/DetailsDrinks'),
		),

		LISTPLATE: lazy(
			() => import('../pages/app/stationKitchen/screen/plate/List'),
		),
		CONFIGUREPLATE: lazy(
			() => import('../pages/app/stationKitchen/screen/plate/ConfigurePlate'),
		),
		EDITPLATE: lazy(
			() => import('../pages/app/stationKitchen/screen/plate/EditPlate'),
		),
		SHOWPLATE: lazy(
			() => import('../pages/app/stationKitchen/screen/plate/ShowPlate'),
		),
		PLATE: lazy(
			() => import('../pages/app/stationKitchen/screen/PlateStation'),
		),
	},
	WORKLINE: {
		LIST: lazy(
			() => import('../pages/app/workLine/List'),
		),
	},
	MEASUREMENTUNIT: {
		LIST: lazy(
			() => import('../pages/app/measurementUnit/List'),
		),
	},
	EMPLOYEE: {
		LIST: lazy(
			() => import('../pages/app/employee/List'),
		),
	},
	EVOLUTION: {
		LIST: lazy(
			() => import('../pages/app/evolution/List'),
		),
	},
	MOVEMENTTYPE: {
		LIST: lazy(
			() => import('../pages/app/movementType/List'),
		),
	},
	TABLE: {
		LIST: lazy(
			() => import('../pages/app/table/List'),
		),
		NOTIFICATION: lazy(
			() => import('../pages/app/notifications/TableNotification'),
		),
	},
	TASKTYPE: {
		LIST: lazy(
			() => import('../pages/app/taskType/List'),
		),
	},
	KANBAN: {
		LIST: lazy(
			() => import('../pages/app/kanban/List'),
		),
	},
	SECTORKITCHEN: {
		LIST: lazy(
			() => import('../pages/app/sectorKitchen/List'),
		)
	},
	DEPOSIT: {
		LIST: lazy(
			() => import('../pages/app/deposit/List'),
		),
	},
	STOCK: {
		LIST: lazy(
			() => import('../pages/app/stock/List'),
		),
	},
	STOCK_CUBE: {
		CUBE: lazy(
			() => import('../pages/app/stock/Cube'),
		),
	},
	STOCK_SORT: {
		SORT: lazy(
			() => import('../pages/app/sortStock/sortStock'),
		),
	},
	ITEMSTOCK: {
		LIST: lazy(
			() => import('../pages/app/itemStock/List'),
		),
	},
	STOCKMOVEMENT: {
		LIST: lazy(
			() => import('../pages/app/stockMovement/List'),
		),
	},
	PRODUCTION_PLAN: {
		LIST: lazy(
			() => import('../pages/app/stock/ProductionPlan'),
		),
	},
	SECTORS: {
		LIST: lazy(
			() => import('../pages/app/sector/List'),
		),
	},
	RESERVATION: {
		LIST: lazy(
			() => import('../pages/app/reservation/List'),
		),
		CONFIRM: lazy(
			() => import('../pages/app/reservation/Confirm'),
		),
		DETAIL: lazy(
			() => import('../pages/app/reservation/Details'),
		),
	},
	TABLET: {
		LIST: lazy(
			() => import('../pages/app/tablet/List'),
		),
		ASSIGN: lazy(
			() => import('../pages/app/tablet/Assign'),
		),
	},
	TASK: {
		LIST: lazy(
			() => import('../pages/app/task/List'),
		),
		SCHEDULE: lazy(
			() => import('../pages/app/task/Schedule'),
		),
	},
	VIEWMODE_TABLE: {
		MENU: lazy(
			() => import('../pages/app/viewModeTable/Menu'),
		),
		QR: lazy(
			() => import('../pages/app/viewModeTable/Positions'),
		),
	},
	VIEWMODE_EMPLOYEE: {
		ASSIGNED_TASK: lazy(
			() => import('../pages/app/viewModeEmployee/AssignedTasks'),
		),
		CURRENT_TASK: lazy(
			() => import('../pages/app/viewModeEmployee/CurrentTask'),
		),
	},
	VIEWMODE_CLIENT: {
		LOGIN: lazy(
			() => import('../pages/app/viewModeClient/Login'),
		),
		WELCOME: lazy(
			() => import('../pages/app/viewModeClient/Welcome'),
		),
		MENU: lazy(
			() => import('../pages/app/viewModeClient/Menu'),
		),
		HELPCLIENT: lazy(
			() => import('../pages/app/viewModeClient/Help'),
		),
	},
	VIEWMODE_STATION: {
		RATIONAL: lazy(
			() => import('../pages/app/viewModeStation/Rational'),
		),
		COMUN: lazy(
			() => import('../pages/app/viewModeStation/Comun'),
		),
		PLATE: lazy(
			() => import('../pages/app/viewModeStation/Plate'),
		),
		CARTFOOD: lazy(
			() => import('../pages/app/viewModeStation/CartFood'),
		),
		CARTDRINK: lazy(
			() => import('../pages/app/viewModeStation/Drink'),
		),
		CARTDRINKDETAIL: lazy(
			() => import('../pages/app/viewModeStation/DrinkDetail'),
		),
		CARTWINE: lazy(
			() => import('../pages/app/viewModeStation/Wine'),
		),
		CARTWINEDETAIL: lazy(
			() => import('../pages/app/viewModeStation/WineDetail'),
		)
	},
	VIEWMODE_CART: {
		CART: lazy(
			() => import('../pages/app/viewModeCart/Cart'),
		),
		DELIVERCART: lazy(
			() => import('../pages/app/viewModeCart/DeliverCart'),
		),
	},
	VIEWMODE_CART_SALON: {
		CARTSALON: lazy(
			() => import('../pages/app/viewModeCartSalon/Cart'),
		)
	},
	USER: {
		LIST: lazy(
			() => import('../pages/app/user/List'),
		),
	},
	ORDER: {
		LIST: lazy(
			() => import('../pages/app/order/List'),
		),
		PROCESS: lazy(
			() => import('../pages/app/order/Process'),
		),
		QUEUE: lazy(
			() => import('../pages/app/order/Queue'),
		),
		TO_PREPARE: lazy(
			() => import('../pages/app/order/ToPrepare'),
		),
		TO_DELIVER: lazy(
			() => import('../pages/app/order/ToDeliver'),
		),
		TO_PREPARE_OLD: lazy(
			() => import('../pages/app/order/ToPrepareOld'),
		),
		TO_DELIVER_OLD: lazy(
			() => import('../pages/app/order/ToDeliverOld'),
		),
		CHEF: lazy(
			() => import('../pages/app/order/Chef'),
		),
	},
	HELP: {
		VIDEO: lazy(
			() => import('../pages/app/help/List'),
		),
	},
};

const presentation: RouteProps[] = [

	/**
	 * Auth Page
	 */
	{
		// path: demoPagesMenu.page404.path,
		path: "auth-pages/404",
		element: <AUTH.PAGE_404 />,
	},
	{
		// path: demoPagesMenu.login.path,
		path: 'auth-pages/login',
		element: <Login />,
	},
	{
		// path: demoPagesMenu.signUp.path,
		path: 'auth-pages/sign-up',
		element: <Login isSignUp />,
	},
	{
		path: 'public/menu',
		element: <Menu />,
	},

	/**
	 * App
	 */

	/**
	 * App > Autism Classification
	 */
	{
		path: dashboardPagesMenu.dashboard.path,
		element: <APP.HOME.LIST />,
	},

	/**
	 * App > Autism Classification
	 */
	{
		path: demoPagesMenu.autismClassification.path,
		element: <APP.AUTISM_CLASSIFICATION.LIST />,
	},

	/**
	 * App > Category
	 */
	{
		path: demoPagesMenu.category.path,
		element: <APP.CATEGORY.LIST />,
	},

	/**
	 * App > Subcategory
	 */
	{
		path: demoPagesMenu.subcategory.path,
		element: <APP.SUBCATEGORY.LIST />,
	},

	/**
	* App > Cart
	*/
	{
		path: demoPagesMenu.cart.path,
		element: <APP.CART.LIST />,
	},
	{
		path: "cart/add",
		element: <APP.CART.ADD />,
	},
	{
		path: "cart/edit/:id",
		element: <APP.CART.EDIT />,
	},
	{
		path: "cart/show/:id",
		element: <APP.CART.SHOW />,
	},
	/**
	 * App > Station
	 */
	{
		path: demoPagesMenu.station.path,
		element: <APP.STATION.LIST />,
	},
	{
		path: "stationKitchen/configureScreen/:id",
		element: <APP.STATION.CONFIGURESCREEN />,
	},
	{
		path: "stationKitchen/common/:id",
		element: <APP.STATION.COMMON />,
	},
	{
		path: "stationKitchen/rational/:id",
		element: <APP.STATION.RATIONAL />,
	},
	{
		path: "stationKitchen/configurePlated/:id",
		element: <APP.STATION.LISTPLATE />,
	},
	{
		path: "stationKitchen/configurePlate/:id",
		element: <APP.STATION.CONFIGUREPLATE />,
	},
	{
		path: "stationKitchen/editPlate/:id/:idStock",
		element: <APP.STATION.EDITPLATE />,
	},
	{
		path: "stationKitchen/showPlate/:id/:idStock",
		element: <APP.STATION.SHOWPLATE />,
	},
	{
		path: "stationKitchen/plate/:id",
		element: <APP.STATION.PLATE />,
	},
	{
		path: "stationKitchen/food/:id",
		element: <APP.STATION.FOOD />,
	},
	{
		path: "stationKitchen/wine/:id",
		element: <APP.STATION.WINE />,
	},
	{
		path: "stationKitchen/wine/detail/:idStation/:idWine",
		element: <APP.STATION.WINEDETAILS />,
	},
	{
		path: "stationKitchen/drinks/:id",
		element: <APP.STATION.DRINKS />,
	},
	{
		path: "stationKitchen/drinks/detail/:idStation/:idDrink",
		element: <APP.STATION.DRINKDETAILS />,
	},

	/**
* App > Work Line
*/
	{
		path: demoPagesMenu.workLine.path,
		element: <APP.WORKLINE.LIST />,
	},

	/**
	 * App > Measurement Unit
	 */
	{
		path: demoPagesMenu.measurementUnit.path,
		element: <APP.MEASUREMENTUNIT.LIST />,
	},

	/**
	 * App > Employee
	 */
	{
		path: demoPagesMenu.employee.path,
		element: <APP.EMPLOYEE.LIST />,
	},

	/**
	 * App > Evolution
	 */
	{
		path: demoPagesMenu.evolution.path,
		element: <APP.EVOLUTION.LIST />,
	},

	/**
	 * App > Movement Type
	 */
	{
		path: demoPagesMenu.movementType.path,
		element: <APP.MOVEMENTTYPE.LIST />,
	},

	/**
	 * App > Production Plan
	 */
	{
		path: demoPagesMenu.productionPlan.path,
		element: <APP.PRODUCTION_PLAN.LIST />,
	},

	/**
	 * App > Sectors
	 */
	{
		path: demoPagesMenu.sector.path,
		element: <APP.SECTORS.LIST />,
	},

	/**
	 * App > Reservations
	 */
	{
		path: demoPagesMenu.importReservation.path,
		element: <APP.RESERVATION.LIST />,
	},
	{
		path: demoPagesMenu.confirmReservation.path,
		element: <APP.RESERVATION.CONFIRM />,
	},
	{
		path: "reservation/confirm/:id",
		element: <APP.RESERVATION.DETAIL />,
	},

	/**
	 * App > Table
	 */
	{
		path: demoPagesMenu.table.path,
		element: <APP.TABLE.LIST />,
	},
	{
		path: demoPagesMenu.tableNotification.path,
		element: <APP.TABLE.NOTIFICATION />,
	},

	/**
	 * App > Task Type
	 */
	{
		path: demoPagesMenu.taskType.path,
		element: <APP.TASKTYPE.LIST />,
	},
	{
		path: demoPagesMenu.kanban.path,
		element: <APP.KANBAN.LIST />,
	},
	{
		path: demoPagesMenu.sectorKitchen.path,
		element: <APP.SECTORKITCHEN.LIST />,
	},


	/**
	 * App > Task Schedule
	 */
	{
		path: demoPagesMenu.taskSchedule.path,
		element: <APP.TASK.SCHEDULE />,
	},

	/**
	 * App > Deposit
	 */
	{
		path: demoPagesMenu.deposit.path,
		element: <APP.DEPOSIT.LIST />,
	},
	/**
	 * App > Stock
	 */
	{
		path: demoPagesMenu.stock.path,
		element: <APP.STOCK.LIST />,
	},
	{
		path: demoPagesMenu.stockCube.path,
		element: <APP.STOCK_CUBE.CUBE />,
	},
	{
		path: demoPagesMenu.stockSort.path,
		element: <APP.STOCK_SORT.SORT />,
	},
	/**
	 * App > Stock
	 */
	{
		path: demoPagesMenu.itemStock.path,
		element: <APP.ITEMSTOCK.LIST />,
	},
	/**
	 * App > Stock Movement
	 */
	{
		path: demoPagesMenu.stockMovement.path,
		element: <APP.STOCKMOVEMENT.LIST />,
	},
	/**
	 * App > Tablet Assign
	 */
	{
		path: demoPagesMenu.assignTablet.path,
		element: <APP.TABLET.ASSIGN />,
	},

	/**
	 * App > Tablet
	 */
	{
		path: demoPagesMenu.tablet.path,
		element: <APP.TABLET.LIST />,
	},

	/**
	 * App > Task Type
	 */
	{
		path: demoPagesMenu.task.path,
		element: <APP.TASK.LIST />,
	},

	/**
	 * App > Assigned Tasks
	 */
	{
		path: employeeMenu.assignedTasks.path,
		element: <APP.VIEWMODE_EMPLOYEE.ASSIGNED_TASK />,
	},
	/**
	 * App > Current Task
	 */
	{
		path: employeeMenu.currentTask.path,
		element: <APP.VIEWMODE_EMPLOYEE.CURRENT_TASK />,
	},
	{
		path: tableMenu.menu.path,
		element: <APP.VIEWMODE_TABLE.MENU />,
	},
	// {
	// 	path: tableMenu.qr.path,
	// 	element: <APP.VIEWMODE_TABLE.QR />,
	// },
	/**
	   * App > Station viewMode
	*/
	{
		path: `${stationMenu.rational.path}/:id`,
		element: <APP.VIEWMODE_STATION.RATIONAL />,
	},
	{
		path: `${stationMenu.comun.path}/:id`,
		element: <APP.VIEWMODE_STATION.COMUN />,
	},
	{
		path: `${stationMenu.plate.path}/:id`,
		element: <APP.VIEWMODE_STATION.PLATE />,
	},
	{
		path: `${stationMenu.food.path}/:id`,
		element: <APP.VIEWMODE_STATION.CARTFOOD />,
	},
	{
		path: `${stationMenu.drink.path}/:id`,
		element: <APP.VIEWMODE_STATION.CARTDRINK />,
	},
	{
		path: `${stationMenu.drinkDetail.path}/:idStation/:idDrink`,
		element: <APP.VIEWMODE_STATION.CARTDRINKDETAIL />,
	},
	{
		path: `${stationMenu.wine.path}/:id`,
		element: <APP.VIEWMODE_STATION.CARTWINE />,
	},
	{
		path: `${stationMenu.wineDetail.path}/:idStation/:idWine`,
		element: <APP.VIEWMODE_STATION.CARTWINEDETAIL />,
	},

	/**
	   * App > Cart viewMode
	*/
	{
		path: cartMenu.menu.path,
		element: <APP.VIEWMODE_CART.CART />,
	},
	{
		path: cartMenu.deliver.path,
		element: <APP.VIEWMODE_CART.DELIVERCART />,
	},
	{
		path: `${cartSalonMenu.salon.path}/:id`,
		element: <APP.VIEWMODE_CART_SALON.CARTSALON />,
	},

	/**
	   * App > Client
	*/
	{
		path: 'client/welcome/:qr',
		element: <APP.VIEWMODE_CLIENT.WELCOME />,
	},
	{
		path: 'client/login/:qr',
		element: <APP.VIEWMODE_CLIENT.LOGIN />,
	},
	{
		path: 'client/help/kind/:kind',
		element: <APP.VIEWMODE_CLIENT.HELPCLIENT />,
	},
	{
		path: clientMenu.menu.path,
		element: <APP.VIEWMODE_CLIENT.MENU />,
	},
	// {
	// 	path: tableMenu.qr.path,
	// 	element: <APP.VIEWMODE_TABLE.QR />,
	// },

	/**
	 * App > Tablet
	 */
	{
		path: demoPagesMenu.user.path,
		element: <APP.USER.LIST />,
	},

	/**
	 * App > Order
	 */
	{
		path: demoPagesMenu.order.path,
		element: <APP.ORDER.LIST />,
	},
	// {
	// 	path: demoPagesMenu.order_process.path,
	// 	element: <APP.ORDER.PROCESS />,
	// },
	// {
	// 	path: demoPagesMenu.queue.path,
	// 	element: <APP.ORDER.QUEUE />,
	// },
	{
		path: demoPagesMenu.chef.path,
		element: <APP.ORDER.CHEF />,
	},
	// {
	// 	path: demoPagesMenu.order_to_deliver.path,
	// 	element: <APP.ORDER.TO_DELIVER />,
	// },
	// {
	// 	path: `${demoPagesMenu.order_to_prepare.path}Old`,
	// 	element: <APP.ORDER.TO_PREPARE_OLD />,
	// },
	// {
	// 	path: `${demoPagesMenu.order_to_deliver.path}Old`,
	// 	element: <APP.ORDER.TO_DELIVER_OLD />,
	// },

	/**
	 * App > Help
	 */
	{
		path: demoPagesMenu.video.path,
		element: <APP.HELP.VIDEO />,
	},

	/**
	 * END - App
	 */

	/** ************************************************** */

];


const contents = [...presentation,];

export default contents;
