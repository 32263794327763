import { RouteProps } from 'react-router-dom';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const asides: RouteProps[] = [
	{ path: 'auth-pages/login', element: null },
	{ path: 'auth-pages/sign-up', element: null },
	{ path: 'auth-pages/404', element: null },
	{ path: 'public/menu', element: null },
	{ path: 'client/welcome/:qr', element: null },
	{ path: 'client/login/:qr', element: null },
	{ path: '*', element: <DefaultAside /> },
];

export default asides;
