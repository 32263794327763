import classNames from 'classnames';
import { useFormik } from 'formik';
import { FC, ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import * as authService from '../../api/authService';
import Logo from '../../assets/logos/logo.jpg';
import { ID_EMPLOYEE_KEY, USER_KEY, USE_COOKIE_KEY, VIEWMODE_KEY } from '../../common/config';
import Button from '../../components/bootstrap/Button';
import Card, { CardBody } from '../../components/bootstrap/Card';
import Spinner from '../../components/bootstrap/Spinner';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import AuthContext from '../../contexts/authContext';
import ThemeContext, { VIEWMODE } from '../../contexts/themeContext';
import useDarkMode from '../../hooks/useDarkMode';
import useEventListener from '../../hooks/useEventListener';
import Page from '../../layout/Page/Page';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = () => {

	const { setUser, setLoginToken, setRefreshToken } = useContext(AuthContext);
	const { viewMode, setViewMode } = useContext(ThemeContext);
	const { t } = useTranslation(['translation']);
	const { darkModeStatus } = useDarkMode();
	const navigate = useNavigate();

	// Keypress submit function
	const enterFunction = (event: { key: string }) => {
		if (event.key === 'Enter') {
			formik.submitForm();
		}
	};
	useEventListener('keydown', enterFunction);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: "",
			password: "",
		},
		validate: (values) => {
			const errors: {
				email?: string;
				password?: string
			} = {};

			if (!values.email) {
				errors.email = t('translation:general.validation.required-field') as string;
			}
			if (!values.password) {
				errors.password = t('translation:general.validation.required-field') as string;
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values, actions) => {

			try {
				const payload = {
					email: values.email,
					password: values.password,
				};

				localStorage.removeItem('waiting_token');
				localStorage.removeItem(USE_COOKIE_KEY);
				localStorage.removeItem(ID_EMPLOYEE_KEY);
				actions.setSubmitting(true);
				const { email, token, refreshToken } = await authService.loginUser(payload);

				localStorage.setItem(USER_KEY, email);
				localStorage.setItem(VIEWMODE_KEY, VIEWMODE.DASHBOARD);

				setLoginToken(token);
				setRefreshToken(refreshToken);
				setViewMode(VIEWMODE.DASHBOARD);

				navigate('/');

			} catch (error: any) {

				const messageKey = error.response && error.response.data && error.response.data.message;
				const message = t(`translation:${messageKey}`, t('translation:general.operation.bad-credentials')) as string;
				formik.setFieldError('password', message);
			}
			finally {
				actions.setSubmitting(false);
			}
		},
	});

	return (
		<PageWrapper
			isProtected={false}
			title={'Login'}
			className={'bg-dark'}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<img src={Logo} width={200} style={{ borderRadius: '2rem' }} />
									</Link>
								</div>

								<div className='text-center h1 fw-bold mt-5'>{t('translation:login.welcome') as ReactNode}</div>
								<div className='text-center h4 text-muted mb-5'>{t('translation:login.welcome.subtitle') as ReactNode}</div>

								<form className='row g-4'>
									<>
										<div className='col-12'>
											<FormGroup
												id='email'
												isFloating
												label='Your email'>
												<Input
													autoComplete='username'
													value={formik.values.email}
													isTouched={formik.touched.email}
													invalidFeedback={
														formik.errors.email
													}
													isValid={formik.isValid}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													onFocus={() => {
														formik.setErrors({});
													}}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup
												id='password'
												isFloating
												label='Password'>
												<Input
													type='password'
													autoComplete='current-password'
													value={formik.values.password}
													isTouched={formik.touched.password}
													invalidFeedback={
														formik.errors.password
													}
													isValid={formik.isValid}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<Button
												color='info'
												className='w-100 py-3'
												isDisable={formik.isSubmitting}
												onClick={formik.handleSubmit}>
												{formik.isSubmitting && (
													<Spinner isSmall inButton isGrow />
												)}
												{t('translation:Login')}
											</Button>
										</div>
									</>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
};
Login.defaultProps = {
};

export default Login;
