import { appApi, tableApi } from '../endpoint';

export function getMenu() {
  return appApi.get(`/api/v1/menu`);
}

export function getMenuFromTableContext() {
  return tableApi.get(`/api/v1/menu`);
}

export function updateMenu(payload) {
  return appApi.put(`/api/v1/menu/order`, payload);
}

export function sortMenu(sortType) {
  return appApi.get(`/api/v1/menu/sort/${sortType}`);
}