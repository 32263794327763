import PropTypes from 'prop-types';
import { createContext, FC, ReactNode, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { LOGIN_TOKEN_KEY, REFRESH_TOKEN_KEY, VIEWMODE_KEY } from '../common/config';
import useDeviceScreen from '../hooks/useDeviceScreen';

export const VIEWMODE = {
	DASHBOARD: 'dashboard',
	TABLET_TABLE: 'table',
	TABLET_EMPLOYEE: 'employee',
	CLIENT: 'client',
	CART: 'cart',
	STATION: 'station',
}
export interface IThemeContextProps {
	asideStatus: boolean;
	darkModeStatus: boolean;
	fullScreenStatus: boolean;
	leftMenuStatus: boolean;
	mobileDesign: boolean;
	rightMenuStatus: boolean;
	rightPanel: boolean;
	setAsideStatus: (value: ((prevState: boolean) => boolean) | boolean) => void;
	setDarkModeStatus: (value: ((prevState: boolean) => boolean) | boolean) => void;
	setFullScreenStatus: (value: ((prevState: boolean) => boolean) | boolean) => void;
	setLeftMenuStatus: (value: ((prevState: boolean) => boolean) | boolean) => void;
	setRightMenuStatus: (value: ((prevState: boolean) => boolean) | boolean) => void;
	setRightPanel: (value: ((prevState: boolean) => boolean) | boolean) => void;

	// viewmode settings
	viewMode: unknown;
	viewModeData: unknown;
	setViewMode(...args: unknown[]): unknown;
	setViewModeData(...args: unknown[]): unknown;
}
const ThemeContext = createContext<IThemeContextProps>({} as IThemeContextProps);

interface IThemeContextProviderProps {
	children: ReactNode;
}
export const ThemeContextProvider: FC<IThemeContextProviderProps> = ({ children }) => {
	const deviceScreen = useDeviceScreen();
	// @ts-ignore
	const mobileDesign = deviceScreen?.width <= process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE;

	const [darkModeStatus, setDarkModeStatus] = useState(
		localStorage.getItem('alamesa_darkModeStatus')
			? localStorage.getItem('alamesa_darkModeStatus') === 'true'
			: process.env.REACT_APP_DARK_MODE === 'true',
	);

	const [viewMode, setViewMode] = useState<string>(localStorage.getItem(VIEWMODE_KEY) || VIEWMODE.DASHBOARD);
	const [viewModeData, setViewModeData] = useState(localStorage.getItem('alamesa_viewModeData') ? JSON.parse(localStorage.getItem('alamesa_viewModeData') || '') : {});

	useEffect(() => {
		if (viewMode) {
			localStorage.setItem(VIEWMODE_KEY, viewMode);

			if (viewMode === VIEWMODE.TABLET_EMPLOYEE || viewMode === VIEWMODE.TABLET_TABLE
				|| viewMode === VIEWMODE.CART || viewMode === VIEWMODE.STATION) {
				localStorage.removeItem(REFRESH_TOKEN_KEY);
				localStorage.removeItem(LOGIN_TOKEN_KEY);
			}

		}
	}, [viewMode]);

	useEffect(() => {
		if (viewModeData) {
			localStorage.setItem('alamesa_viewModeData', JSON.stringify(viewModeData));
		}
	}, [viewModeData]);

	useLayoutEffect(() => {
		localStorage.setItem('alamesa_darkModeStatus', darkModeStatus.toString());
	}, [darkModeStatus]);

	const [fullScreenStatus, setFullScreenStatus] = useState(false);

	const [leftMenuStatus, setLeftMenuStatus] = useState(false);
	const [rightMenuStatus, setRightMenuStatus] = useState(false);
	const [asideStatus, setAsideStatus] = useState(
		localStorage.getItem('alamesa_asideStatus')
			? localStorage.getItem('alamesa_asideStatus') === 'true'
			: // @ts-ignore
			deviceScreen?.width >= process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE,
	);
	useLayoutEffect(() => {
		localStorage.setItem('alamesa_asideStatus', asideStatus?.toString());
	}, [asideStatus]);

	const [rightPanel, setRightPanel] = useState(false);

	useLayoutEffect(() => {
		// @ts-ignore
		if (deviceScreen?.width >= process.env.REACT_APP_ASIDE_MINIMIZE_BREAKPOINT_SIZE) {
			if (localStorage.getItem('alamesa_asideStatus') === 'true') setAsideStatus(true);
			setLeftMenuStatus(false);
			setRightMenuStatus(false);
		}
		return () => {
			setAsideStatus(false);
		};
	}, [deviceScreen.width]);

	const values: IThemeContextProps = useMemo(
		() => ({
			mobileDesign,
			darkModeStatus,
			setDarkModeStatus,
			fullScreenStatus,
			setFullScreenStatus,
			asideStatus,
			setAsideStatus,
			leftMenuStatus,
			setLeftMenuStatus,
			rightMenuStatus,
			setRightMenuStatus,
			rightPanel,
			setRightPanel,

			viewMode,
			viewModeData,
			setViewMode,
			setViewModeData,
		}),
		[
			asideStatus,
			darkModeStatus,
			fullScreenStatus,
			leftMenuStatus,
			mobileDesign,
			rightMenuStatus,
			rightPanel,

			viewMode,
			viewModeData,
			setViewMode,
			setViewModeData,
		],
	);

	return <ThemeContext.Provider value={values}>{children}</ThemeContext.Provider>;
};
ThemeContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default ThemeContext;
