import axios from 'axios';
import { getLoginToken, getRefreshToken, getTableToken, refreshSessionToken } from '../api/authService';
import { AUTHENTICATION_REQUIRED, UNAUTHORIZED_ERROR_CODE, USE_COOKIE_KEY } from '../common/config';
import * as storageUtils from './storageUtils';

export const createInstance = (url: string) => {
  return axios.create({
    timeout: 0,
    baseURL: url,
  });
};

export const createAuthInstance = (url: string) => {
  const instance = axios.create({
    baseURL: url,
    timeout: 0,
    headers: {
      common: {},
    },
    withCredentials: true,
  });
  interceptRequest(instance);
  interceptResponse(instance);
  return instance;
};

export const interceptRequest = (instance: any) => {
  instance.interceptors.request.use(async (config: any) => {
    const useCookie = localStorage.getItem(USE_COOKIE_KEY) === 'true';
    if (useCookie) {
      config.headers.Authorization = undefined;
    } else {
      config.headers.Authorization = `Bearer ${getLoginToken()}`;
    }
    return config;
  });
};

export const interceptResponse = (endpoint: any) => {
  endpoint.interceptors.response.use(
    function (response: any) {
      return response;
    },
    function (error: any) {

      const useCookie = localStorage.getItem(USE_COOKIE_KEY) === 'true';
      const loginPath = `/auth-pages/login`;
      const errorCode = error.response && error.response.status || null;

      // using cookie
      if (useCookie) {
        if (error?.response?.status === UNAUTHORIZED_ERROR_CODE) {
          window.location.href = loginPath;
        }
        return Promise.reject(error);
      }

      // using jwt
      if (!useCookie) {

        if (errorCode === AUTHENTICATION_REQUIRED && !getRefreshToken()) {
          window.location.href = loginPath;
          return Promise.reject(error);
        }

        if (errorCode === AUTHENTICATION_REQUIRED && !error.config.retryRequest) {

          const waitingToken = storageUtils.isWaitingToken();

          if (waitingToken) {
            return new Promise(((resolve, reject) => {
              const timer = setInterval(() => {
                const updating = storageUtils.isWaitingToken();
                if (!updating) {
                  clearInterval(timer);
                  // Token refresh finished
                  endpoint.defaults.headers.common.Authorization = `Bearer ${getLoginToken()}`;
                  error.config.headers.Authorization = `Bearer ${getLoginToken()}`;
                  resolve(axios(error.config));
                }
              }, 100);
            }));
          }

          return new Promise((resolve, reject) => {
            storageUtils.setWaitingToken(true);
            refreshSessionToken().then((token: string) => {
              error.config.retryRequest = true;
              endpoint.defaults.headers.common.Authorization = `Bearer ${token}`;
              error.config.headers.Authorization = `Bearer ${token}`;
              resolve(axios(error.config));
            }).catch(e => {
              reject(e);
            }).finally(() => {
              storageUtils.setWaitingToken(false);
            });
          });

        }
      }

      if (error.response && error.response.status === UNAUTHORIZED_ERROR_CODE) {
        window.location.href = loginPath;
      }

      return Promise.reject(error);
    },
  );
};

export const createTableAuthInstance = (url: string) => {
  const instance = axios.create({
    baseURL: url,
    timeout: 0,
    headers: {
      common: {},
    },
  });
  interceptTableRequest(instance);
  return instance;
};

export const interceptTableRequest = (instance: any) => {
  instance.interceptors.request.use(async (config: any) => {
    config.headers.Authorization = `Bearer ${getTableToken()}`;
    return config;
  });
};