import { appApi, tableApi } from '../endpoint';

export function getOrder(id) {
  return appApi.get(`/api/v1/order/${id}`);
}

export function createOrder(payload) {
  return appApi.post(`/api/v1/order`, payload);
}

export function updateOrder(payload) {
  return appApi.put(`/api/v1/order`, payload);
}

export function listOrder(filters) {
  return appApi.post(`/api/v1/order/list`, filters);
}

export function deleteOrder(id) {
  return appApi.delete(`/api/v1/order/${id}`);
}

export function getAllOrder(options) {
  return appApi.get(`/api/v1/order`, options);
}

export function createOrderFromTableContext(payload) {
  return tableApi.post(`/api/v1/order`, payload);
}

export function updateOrderFromTableContext(payload) {
  return tableApi.put(`/api/v1/order`, payload);
}

export function getCurrentOrder() {
  return appApi.get(`/api/v1/order/current`);
}

export function cancelCurrentOrder() {
  return appApi.delete(`/api/v1/order/current`);
}

export function upsertCurrentOrder(payload) {
  return appApi.put(`/api/v1/order/current`, payload);
}

export function payOrder(payload) {
  return appApi.post(`/api/v1/order/current/pay`, payload);
}

export function verifyPayment(payload) {
  return appApi.post(`/api/v1/orderPayment/status`, payload);
}

export function getAllOrderStockSorted() {
  return appApi.get(`/api/v1/orderStock/sorted`);
}

export function updateOrderStockPreparationStatus(payload) {
  return appApi.put(`/api/v1/orderStock/preparationStatus`, payload);
}

export function updateOrderStockIngredientPreparationStatus(payload) {
  return appApi.put(`/api/v1/orderStock/preparationStatus/ingredient`, payload);
}

export function getAllOrderByStatus(statusId) {
  return appApi.put(`/api/v1/order/status/${statusId}`);
}

export function getAllOrderStockByStatus(payload) {
  return appApi.post(`/api/v1/orderStock/preparationStatus`, payload);
}

export function updateMultiOrderStockPreparationStatus(payload) {
  return appApi.put(`/api/v1/orderStock/preparationStatus/multi`, payload);
}

export function requestAssitanceWaiter(payload) {
  return appApi.post(`/api/v1/request/assistance-waiter`, payload);
}

export function requestAssitanceCustomer() {
  return appApi.get(`/api/v1/request/assistance-customer`);
}

export function getCurrentOrderByTable(payload) {
  return appApi.post(`/api/v1/order/table`, payload);
}