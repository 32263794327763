import PropTypes from 'prop-types';
import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';

export interface IOrderStock {
	idStock: any,
	quantity: number,
	stock?: any,
	tablePosition?: number,
}

export interface ICardProps {
	id?: number;
	idOrderStatusType?: number;
	orderStocks: Partial<IOrderStock>[];
	orderStatusType?: any;
	paymentInitPoint?: string;
	orderStatusPosition?: [];
}
export interface ICartContextProps {
	cartData: Partial<ICardProps>;
	tablePosition: number;
	idTable: number;
	total: number;
	setCartData(...args: unknown[]): unknown;
	addProduct(...args: unknown[]): unknown;
	deleteProduct(...args: unknown[]): unknown;
	setQuantity(...args: unknown[]): unknown;
	setTablePosition(...args: unknown[]): unknown;
	setIdTable(...args: unknown[]): unknown;
}
const CartContext = createContext<ICartContextProps>({} as ICartContextProps);
interface ICartContextProviderProps {
	children: ReactNode;
}
export const CartContextProvider: FC<ICartContextProviderProps> = ({ children }) => {

	const [cartData, setCartData] = useState<Partial<ICardProps>>(JSON.parse(localStorage.getItem('alamesa_cartData') || '{}'));
	const [tablePosition, setTablePosition] = useState(Number(localStorage.getItem('alamesa_tablePosition') || 1));
	const [idTable, setIdTable] = useState(Number(localStorage.getItem('alamesa_idTable') || 0));

	useEffect(() => {
		localStorage.setItem('alamesa_cartData', JSON.stringify(cartData));
	}, [cartData]);

	const addProduct = (stock: any) => {
		const orderStocks = cartData.orderStocks || [];
		const index = orderStocks.findIndex((item: any) => item.idStock === stock.id);

		if (index === -1) {
			orderStocks.push({
				idStock: stock.id,
				quantity: 1,
				stock: stock,
			})
		} else {
			orderStocks[index].quantity = Number(orderStocks[index].quantity) + 1;
		}

		setCartData(prev => ({
			...prev,
			orderStocks: orderStocks,
		}));
	}

	const deleteProduct = (idStock: number) => {
		setCartData(prev => ({
			...prev,
			orderStocks: prev.orderStocks?.filter((item: any) => item.idStock !== idStock),
		}));
	}

	const setQuantity = (idStock: number, quantity: number) => {
		const orderStocks = cartData.orderStocks || [];
		const index = orderStocks.findIndex((item: any) => item.idStock === idStock);

		if (index === -1) {
			orderStocks.push({
				idStock: idStock,
				quantity: quantity,
			})
		} else {
			orderStocks[index].quantity = quantity;
		}

		setCartData(prev => ({
			...prev,
			orderStocks: orderStocks,
		}));
	}

	const value = useMemo(
		() => ({
			setCartData,
			cartData,
			tablePosition,
			idTable,
			addProduct,
			deleteProduct,
			setQuantity,
			setTablePosition,
			setIdTable,
		}),
		[
			cartData,
			tablePosition,
			setCartData,
			addProduct,
			deleteProduct,
			setQuantity,
			setTablePosition,
			setIdTable,
		],
	);
	// @ts-ignore
	return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
};
CartContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default CartContext;
