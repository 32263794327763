export const FORM_TYPE = {
    SHOW: 'SHOW',
    NEW: 'NEW',
    EDIT: 'EDIT',
};

export const decimalHandleKeyEvent = (e: any) => {
    const event = e || window.event;
    const charCode = (typeof event.which === 'undefined') ? event.keyCode : event.which;
    const charStr = String.fromCharCode(charCode);

    const regExpNumber = /^[+-]?\d?(\.\d?)?$/;
    if (!charStr.match(regExpNumber)) {
        event.preventDefault();
    }
};

export const integerHandleKeyEvent = (e: any) => {
    const event = e || window.event;
    const charCode = (typeof event.which === 'undefined') ? event.keyCode : event.which;
    const charStr = String.fromCharCode(charCode);

    const regExpNumber = /^([+-]?[1-9]\d*|0)$/;
    if (!charStr.match(regExpNumber)) {
        event.preventDefault();
    }
};

export const decimalHandlePasteEvent = (e: any) => {
    const value = e.clipboardData.getData('Text');
    const regExpNumber = /^[+-]?\d+(\.\d+)?$/;
    if (!value.match(regExpNumber)) {
        e.preventDefault();
    }
};

export const integerHandlePasteEvent = (e: any) => {
    const value = e.clipboardData.getData('Text');
    const regExpNumber = /^([+-]?[1-9]\d*|0)$/;
    if (!value.match(regExpNumber)) {
        e.preventDefault();
    }
};
