import { orderBy, toLower } from 'lodash';
import { useEffect, useState } from 'react';
import useFetch from './useFetch';

export interface IOrderBy {
    attrs: string[];
    dirs: ("asc" | "desc")[];
}

const defaultOrderOpts: IOrderBy = {
    attrs: ['label'],
    dirs: ['asc'],
}

// @ts-ignore
const useRemoteOptions = ({ requestData, initialValue = [], deps = [], optionKey = 'id', optionName = 'name', orderOpts = defaultOrderOpts, paramsRequest = null }: any) => {

    const [data, loading, setData, getData] = useFetch({ requestData, deps, initialValue, paramsRequest });
    const [options, setOptions] = useState(initialValue);

    const nameFunc = (item: any) => {
        if (typeof optionName === 'string') {
            return item[optionName];
        }
        if (typeof optionName === 'function') {
            // @ts-ignore
            return optionName(item);
        }
        return '';
    };

    const valueFunc = (item: any) => {
        if (typeof optionKey === 'string') {
            return item[optionKey];
        }
        if (typeof optionKey === 'function') {
            // @ts-ignore
            return optionKey(item);
        }
        return '';
    };

    useEffect(() => {
        if (data) {

            let temp = data.map((item: any) => {
                const name = nameFunc(item);
                const value = valueFunc(item);
                return {
                    name: name,
                    value: value,
                    id: item.id,
                    label: name,
                    text: name,
                    ...item,
                }
            });

            if (orderOpts) {
                const arr = orderOpts.attrs.map((attr: string | number) => ((item: any) => toLower(item[attr])));
                temp = orderBy(
                    temp,
                    arr,
                    orderOpts.dirs,
                );
            }

            setOptions(temp);
        } else {
            setOptions([]);
        }
    }, [data]);

    return [options, loading, setData, getData];
};

useRemoteOptions.propTypes = {};

export default useRemoteOptions;