import { useCallback, useEffect, useState } from 'react';

const useFetch = ({ requestData, deps = [], initialValue = [], paramsRequest = null }: any) => {

    const [data, setData] = useState<any>(initialValue);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<unknown>(null);

    const getData = useCallback(async () => {
        setError(null);
        try {
            setLoading(true);
            const response = paramsRequest ? await requestData(paramsRequest) : await requestData();
            setData(response.data);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setError(e);
        }
    }, deps);

    useEffect(() => {
        getData();
    }, [getData]);

    return [data, loading, setData, getData, error];
};

useFetch.propTypes = {};

export default useFetch;