export enum OrderStatusType {
    OrderStatusStarted = 1,                // "Iniciando pedido"
    OrderStatusFinished,                   // "Pedido completado"
    OrderStatusFinishedPayment,            // "Finalizada cumplida con pago"
    OrderStatusFinishedWithoutPayment,     // "Finalizada cumplida sin pago"
    OrderStatusFinishedNoPayment,          // "Finalizada no cumplida con pago"
    OrderStatusFinishedNoWithoutPayment,   // "Finalizada no cumplida sin pago"
    OrderStatusPendingPrepare,             // "Pendiente de preparación"
    OrderStatusPreparing,                  // "En preparación"
    OrderStatusRedyToDeliver,              // "Listo para servir a la mesa"
    OrderStatusDelivered,                  // "Servido a la mesa"
    OrderStatusReadyToPay,                 // "Pendiente de pago"
};