import classNames from 'classnames';
import { ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserImage2 from '../../assets/img/wanna/wanna2.png';
import Button from '../../components/bootstrap/Button';
import Collapse from '../../components/bootstrap/Collapse';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Icon from '../../components/icon/Icon';
import AuthContext from '../../contexts/authContext';
import ThemeContext, { VIEWMODE } from '../../contexts/themeContext';
import useDarkMode from '../../hooks/useDarkMode';
import useNavigationItemHandle from '../../hooks/useNavigationItemHandle';
import { NavigationLine } from '../Navigation/Navigation';

const User = () => {
	const { userData, tabletData, logout } = useContext(AuthContext);
	const { viewMode } = useContext(ThemeContext);

	const navigate = useNavigate();
	const handleItem = useNavigationItemHandle();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

	const { t } = useTranslation(['translation', 'menu']);

	let displayName = '';
	let position = '';
	let subtitle = '';

	if (viewMode === VIEWMODE.DASHBOARD && userData) {
		displayName = `${userData.name} ${userData.lastname}`;
		//@ts-ignore
		position = userData.userType;
		subtitle = userData.email || '';
	}

	if ((viewMode === VIEWMODE.STATION) && tabletData) {
		displayName = `${tabletData.stationKitchen?.name}`;
		subtitle = tabletData.tabletType?.name;
	}

	if ((viewMode === VIEWMODE.CART) && tabletData) {
		displayName = `${tabletData.deliveryCart?.name}`;
		subtitle = tabletData.tabletType?.name;
	}

	if ((viewMode === VIEWMODE.TABLET_EMPLOYEE) && tabletData) {
		displayName = `${tabletData.employee?.name} ${tabletData.employee?.lastname}`;
		subtitle = tabletData.tabletType?.name;
	}

	if ((viewMode === VIEWMODE.TABLET_TABLE) && tabletData) {
		displayName = `${tabletData.table?.name}`;
		subtitle = tabletData.tabletType?.name;
	}

	if ((viewMode === VIEWMODE.CLIENT) && tabletData) {
		displayName = `${userData.name}`;
	}

	return (
		<>
			<div
				className={classNames('user', { open: collapseStatus })}
				role='presentation'
				onClick={() => setCollapseStatus(!collapseStatus)}>
				<div className='user-avatar'>
					<img
						// srcSet={userData?.srcSet}
						src={UserImage2}
						alt='Avatar'
						width={128}
						height={128}
					/>
				</div>
				<div className='user-info'>
					<div className='user-name d-flex align-items-center'>
						{displayName}
						<Icon icon='Verified' className='ms-1' color='info' />
					</div>
					<div className='user-sub-title'>{subtitle}</div>
				</div>
			</div>
			<DropdownMenu>
				<DropdownItem>
					<Button
						icon='AccountBox'
						onClick={() =>
							navigate(
								`../auth-pages/404/${userData?.idUser}`,
							)
						}>
						Profile
					</Button>
				</DropdownItem>
				<DropdownItem>
					<Button
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem>
			</DropdownMenu>

			<Collapse isOpen={collapseStatus} className='user-menu'>
				<nav aria-label='aside-bottom-user-menu'>
					<div className='navigation'>
						{/* <div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() =>
								navigate(
									`../auth-pages/404/${userData?.idUser}`,
									// @ts-ignore
									handleItem(),
								)
							}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='AccountBox' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Profile') as ReactNode}
									</span>
								</span>
							</span>
						</div> */}
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								setDarkModeStatus(!darkModeStatus);
								handleItem();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon
										icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
										color={darkModeStatus ? 'info' : 'warning'}
										className='navigation-icon'
									/>
									<span className='navigation-text'>
										{darkModeStatus
											? (t('menu:DarkMode') as ReactNode)
											: (t('menu:LightMode') as ReactNode)}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
				<NavigationLine />
				<nav aria-label='aside-bottom-user-menu-2'>
					<div className='navigation'>
						<div
							role='presentation'
							className='navigation-item cursor-pointer'
							onClick={() => {
								logout();
							}}>
							<span className='navigation-link navigation-link-pill'>
								<span className='navigation-link-info'>
									<Icon icon='Logout' className='navigation-icon' />
									<span className='navigation-text'>
										{t('menu:Logout') as ReactNode}
									</span>
								</span>
							</span>
						</div>
					</div>
				</nav>
			</Collapse>
		</>
	);
};

export default User;
