import { useState } from 'react';

const useModal = () => {

  const [state, setState] = useState({
    isOpen: false,
    data: null,
  });

  const toggleModalState = () => {
    setState(prev => ({ ...prev, isOpen: !prev.isOpen }));
  }

  const open = (data: any) => {
    setState(prev => ({ ...prev, isOpen: true, data: data || null }));
  };

  const close = () => {
    setState(prev => ({ ...prev, isOpen: false, data: null }));
  };

  const setIsOpen = (value: boolean) => {
    setState(prev => ({ ...prev, isOpen: value }));
  }

  return {
    isOpen: state.isOpen,
    data: state.data || null,
    state,
    toggleModalState,
    open,
    close,
    setIsOpen,
  };
};

useModal.propTypes = {};

export default useModal;