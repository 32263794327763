import { UserPermission } from "./common/permission";

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const demoPagesMenu = {

	staff: {
		id: 'staff',
		text: 'Staff',
		icon: 'SupervisorAccount',
		permissions: [
			UserPermission.PermissionManageStaff,
			UserPermission.PermissionTherapistMonitoring,
		],
	},
	employee: {
		id: 'employees',
		text: 'Employees',
		path: 'employee/list',
		icon: 'SupervisorAccount',
		permissions: [
			UserPermission.PermissionManageStaff,
		],
	},
	autismClassification: {
		id: 'autismClassification',
		text: 'Autism Classification',
		path: 'autismClassification/list',
		icon: 'MergeType',
		permissions: [
			UserPermission.PermissionAutismClassification,
		],
	},
	evolution: {
		id: 'evolution',
		text: 'Evolution',
		path: 'evolution/list',
		icon: 'Timeline',
		permissions: [
			UserPermission.PermissionTherapistMonitoring,
		],
	},

	saloonGroup: {
		id: 'saloon-group',
		text: 'Saloon',
		icon: 'FoodBank',
		permissions: [],
	},

	// tableGroup: {
	// 	id: 'tableGroup',
	// 	text: 'Tables Config',
	// 	icon: 'FoodBank',
	// 	permissions: [],
	// },
	// sectorkGroup: {
	// 	id: 'sector-group',
	// 	text: 'Sector',
	// 	icon: 'Category',
	// 	permissions: [],
	// },
	// reservationGroup: {
	// 	id: 'reservation-group',
	// 	text: 'Reservation',
	// 	icon: 'Category',
	// 	permissions: [],
	// },
	// taskGroup: {
	// 	id: 'taskGroup',
	// 	text: 'Tasks',
	// 	icon: 'SupervisorAccount',
	// 	permissions: [],
	// },	
	// orderGroup: {
	// 	id: 'orderGroup',
	// 	text: 'Order',
	// 	icon: 'FoodBank',
	// 	permissions: [],
	// },
	// helpGroup: {
	// 	id: 'helpGroup',
	// 	text: 'Help',
	// 	icon: 'Help',
	// 	permissions: [],
	// },

	table: {
		id: 'table',
		text: 'Table',
		path: 'table/list',
		icon: 'FoodBank',
		permissions: [],
	},

	sector: {
		id: 'sector',
		text: 'Sectors of the Room',
		path: 'sector-room/list',
		icon: 'Category',
		permissions: [],
	},

	importReservation: {
		id: 'import-reservation',
		text: 'Import Reservations',
		path: 'reservation/list',
		icon: 'ImportContacts',
		permissions: [],
	},

	confirmReservation: {
		id: 'confirm-reservation',
		text: 'Confirm Reservations',
		path: 'reservation/confirm',
		icon: 'ImportContacts',
		permissions: [],
	},

	stockGroup: {
		id: 'kitchen-group',
		text: 'Kitchen',
		icon: 'SupervisorAccount',
		permissions: [],
	},
	measurementUnit: {
		id: 'measurementUnit',
		text: 'Measurement Units',
		path: 'measurementUnit/list',
		icon: 'AdUnits',
		permissions: [],
	},
	deposit: {
		id: 'deposit',
		text: 'Deposit',
		path: 'deposit/list',
		icon: 'Extension',
		permissions: [],
	},
	stock: {
		id: 'stock',
		text: 'Stock',
		path: 'stock/list',
		icon: 'Fastfood',
		permissions: [],
	},
	stockCube: {
		id: 'stockCube',
		text: 'Stock Cube',
		path: 'stock/cube',
		icon: 'Ballot',
		permissions: [],
	},
	itemStock: {
		id: 'itemStock',
		text: 'Item Stock',
		path: 'itemStock/list',
		icon: 'Summarize',
		permissions: [],
	},
	movementType: {
		id: 'movementType',
		text: 'Movement Types',
		path: 'movementType/list',
		icon: 'DriveFileMove',
		permissions: [],
	},
	stockMovement: {
		id: 'stockMovement',
		text: 'Stock Movements',
		path: 'stockMovement/list',
		icon: 'East',
		permissions: [],
	},

	productionPlan: {
		id: 'productionPlan',
		text: 'Production Plan',
		path: 'productionPlan/list',
		icon: 'EditNote',
		permissions: [],
	},

	taskType: {
		id: 'taskType',
		text: 'Task Types',
		path: 'taskType/list',
		icon: 'AdUnits',
		permissions: [],
	},
	task: {
		id: 'task',
		text: 'Tasks',
		path: 'task/list',
		icon: 'DriveFileMove',
		permissions: [],
	},
	taskSchedule: {
		id: 'taskSchedule',
		text: 'Tasks Schedule',
		path: 'task/schedule',
		icon: 'Schedule',
		permissions: [],
	},
	kanban: {
		id: 'kanban',
		text: 'Kanban',
		path: 'task/kanban',
		icon: 'AppRegistration',
		permissions: [],
	},
	sectorKitchen: {
		id: 'sectorKitchen',
		text: 'Sectors Kitchen',
		path: 'sectorKitchen/List',
		icon: 'Category',
		permissions: [],
	},
	cart: {
		id: 'cart',
		text: 'Cart management',
		path: 'cart/list',
		icon: 'ShoppingCart',
		permissions: [],
	},
	station: {
		id: 'station',
		text: 'Station',
		path: 'station/list',
		icon: 'Category',
		permissions: [],
	},
	workLine: {
		id: 'workLine',
		text: 'Work Line',
		path: 'workLine/list',
		icon: 'Category',
		permissions: [],
	},
	category: {
		id: 'category',
		text: 'Category',
		path: 'category/list',
		icon: 'Category',
		permissions: [],
	},
	subcategory: {
		id: 'subcategory',
		text: 'Subcategory',
		path: 'subCategory/list',
		icon: 'Category',
		permissions: [],
	},

	stockSort: {
		id: 'stockSort',
		text: 'Stock Sort',
		path: 'stock/sort',
		icon: 'Sort',
		permissions: [],
	},
	order: {
		id: 'order',
		text: 'Order',
		path: 'order/list',
		icon: 'FoodBank',
		permissions: [],
	},
	// order_process: {
	// 	id: 'order_process',
	// 	text: 'Process Order',
	// 	path: 'order/process',
	// 	icon: 'FoodBank',
	// 	permissions: [],
	// },
	chef: {
		id: 'chef',
		text: 'Chef',
		path: 'order/chef',
		icon: 'FoodBank',
		permissions: [],
	},
	// queue: {
	// 	id: 'queue',
	// 	text: 'Queue',
	// 	path: 'order/queue',
	// 	icon: 'AccessTime',
	// 	permissions: [],
	// },
	// order_to_prepare: {
	// 	id: 'order_to_prepare',
	// 	text: 'To Prepare',
	// 	path: 'order/to_prepare',
	// 	icon: 'AccessTime',
	// 	permissions: [],
	// },
	// order_to_deliver: {
	// 	id: 'order_to_deliver',
	// 	text: 'To Deliver',
	// 	path: 'order/to_deliver',
	// 	icon: 'AccessTime',
	// 	permissions: [],
	// },


	notificationGroup: {
		id: 'notificationGroup',
		text: 'Notifications',
		icon: 'Notifications',
		permissions: [],
	},
	tableNotification: {
		id: 'tableNotification',
		text: 'Notification',
		path: 'table/notifications',
		icon: 'Notifications',
		permissions: [],
	},

	tabletGroup: {
		id: 'tabletGroup',
		text: 'Tablet',
		icon: 'FoodBank',
		permissions: [],
	},
	tablet: {
		id: 'tablet',
		text: 'Tablet',
		path: 'tablet/list',
		icon: 'DriveFileMove',
		permissions: [],
	},
	assignTablet: {
		id: 'assignTablet',
		text: 'Tablet Assign',
		path: 'tablet/assign',
		icon: 'FoodBank',
		permissions: [],
	},

	configurationGroup: {
		id: 'securityGroup',
		text: 'Configuration',
		icon: 'FoodBank',
		permissions: [],
	},
	user: {
		id: 'user',
		text: 'User',
		path: 'user/list',
		icon: 'VerifiedUser',
		permissions: [],
	},

	video: {
		id: 'video',
		text: 'Ayuda',
		path: 'help/list',
		icon: 'Help',
		permissions: [UserPermission.PermissionFaq],
	},
};

export const employeeMenu = {
	tabletGroup: {
		id: 'employeeGroup',
		text: 'Employee Menu',
		icon: 'SupervisorAccount',
		permissions: [],
	},
	assignedTasks: {
		id: 'assignedTasks',
		text: 'Assigned Tasks',
		path: 'employee/assignedTasks',
		icon: 'AddTask',
		permissions: [],
	},
	currentTask: {
		id: 'currentTask',
		text: 'Current Task',
		path: 'employee/currentTask',
		icon: 'AddRoad',
		permissions: [],
	},
};

export const tableMenu = {
	tabletGroup: {
		id: 'employeeGroup',
		text: 'Employee Menu',
		icon: 'SupervisorAccount',
		permissions: [],
	},
	menu: {
		id: 'menu',
		text: 'Menu',
		path: '/employee/menu',
		icon: 'FoodBank',
		permissions: [],
	},
	// qr: {
	// 	id: 'qrCodes',
	// 	text: 'QrCodes',
	// 	path: '/employee/qr',
	// 	icon: 'QrCode',
	// 	permissions: [],
	// },
};

export const clientMenu = {
	clientGroup: {
		id: 'clientGroup',
		text: '',
		icon: 'SupervisorAccount',
		permissions: [],
	},
	menu: {
		id: 'menu',
		text: 'Menu',
		path: '/client/menu',
		icon: 'FoodBank',
		permissions: [],
	},
};

export const cartMenu = {
	clientGroup: {
		id: 'clientGroup',
		text: '',
		icon: 'SupervisorAccount',
		permissions: [],
	},
	menu: {
		id: 'cart',
		text: 'Cart',
		path: '/cart/load',
		icon: 'FoodBank',
		permissions: [],
	},
	deliver: {
		id: 'cart',
		text: 'Cart',
		path: '/cart/deliver',
		icon: 'FoodBank',
		permissions: [],
	},
};

export const stationMenu = {
	stationGroup: {
		id: 'stationGroup',
		text: '',
		icon: 'SupervisorAccount',
		permissions: [],
	},
	rational: {
		id: 'rational',
		text: 'rational',
		path: '/station/rational',
		icon: 'FoodBank',
		permissions: [],
	},
	comun: {
		id: 'comun',
		text: 'comun',
		path: '/station/common',
		icon: 'FoodBank',
		permissions: [],
	},
	plate: {
		id: 'plate',
		text: 'plate',
		path: '/station/plate',
		icon: 'FoodBank',
		permissions: [],
	},
	food: {
		id: 'food',
		text: 'food',
		path: '/station/cartFood',
		icon: 'FoodBank',
		permissions: [],
	},
	drink: {
		id: 'drink',
		text: 'drink',
		path: '/station/cartDrink',
		icon: 'FoodBank',
		permissions: [],
	},
	drinkDetail: {
		id: 'drinkDetail',
		text: 'drinkDetail',
		path: '/station/cartDrink/detail',
		icon: 'FoodBank',
		permissions: [],
	},
	wine: {
		id: 'wine',
		text: 'wine',
		path: '/station/cartWine',
		icon: 'FoodBank',
		permissions: [],
	},
	wineDetail: {
		id: 'wineDetail',
		text: 'wineDetail',
		path: '/station/cartWine/detail',
		icon: 'FoodBank',
		permissions: [],
	},
	salon: {
		id: 'salon',
		text: 'salon',
		path: '/station/cartSalon',
		icon: 'FoodBank',
		permissions: [],
	},
};


export const cartSalonMenu = {
	clientGroup: {
		id: 'clientGroup',
		text: '',
		icon: 'SupervisorAccount',
		permissions: [],
	},
	salon: {
		id: 'salon',
		text: 'Cart Salon',
		path: '/cart/salon',
		icon: 'FoodBank',
		permissions: [],
	}
};