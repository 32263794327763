import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { ReactNode, useContext, useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import * as menuApi from '../../../api/menuApi';
import * as orderApi from '../../../api/orderApi';
import { integerHandleKeyEvent, integerHandlePasteEvent } from '../../../common/form';
import ScreenLoader from '../../../components/ScreenLoader';
import Button from '../../../components/bootstrap/Button';
import OffCanvas, {
  OffCanvasBody,
  OffCanvasHeader,
  OffCanvasTitle
} from '../../../components/bootstrap/OffCanvas';
import Spinner from '../../../components/bootstrap/Spinner';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import showNotification from '../../../components/extras/showNotification';
import CartContext from '../../../contexts/cartContext';
import useFetch from '../../../hooks/useFetch';

interface IOrderStock {
  idStock: number;
  quantity: number;
}

const Cart = (props: any) => {

  const { t } = useTranslation(['menu', 'translation']);

  const {
    isOpen,
    setIsOpen,
    data,
    onSuccessSubmit,
    onFailSubmit,
  } = props;

  const { cartData, addProduct, setQuantity, deleteProduct } = useContext(CartContext);

  const emptyInitialValues = {
    idOrderStatusType: 1,
    orderStocks: [],
  };
  const [initialValues, setInitialValues] = useState(emptyInitialValues);

  const [menu, loadingMenu] = useFetch({
    requestData: isOpen ? menuApi.getMenu : { data: {} },
    deps: [isOpen],
    initialValue: { data: {} },
  });

  // const [taskResources, loadingResources] = useFetch({ requestData: (data && isOpen) ? () => taskApi.getTaskResources(data) : null, deps: [data, isOpen] });
  // const [depositOptions, loadingDepositOptions, setDepositOptions, reloadDepositOptions] = useRemoteOptions({ requestData: depositApi.getAllDeposit, initialValue: [] });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validate: (values) => {
      const errors: {
        idOrderStatusType?: string;
        orderStocks?: string;
      } = {};

      if (!values.idOrderStatusType) {
        errors.idOrderStatusType = t('translation:general.validation.required-field') as string;
      }

      if ((!values.orderStocks || !values.orderStocks.length)) {
        errors.orderStocks = t('translation:general.validation.required-field') as string;
      } else {

      }

      return errors;
    },
    validateOnChange: false,
    onSubmit: async (values, actions) => {

      try {

        actions.setSubmitting(true);
        await orderApi.updateOrderFromTableContext({
          ...values,
          idOrderStatusType: 2,
          id: cartData.id,
          // @ts-ignore
          orderStocks: (values.orderStocks || []).map(item => ({ ...item, quantity: Number(item.quantity) }))
        });

        showNotification(
          t('translation:general.operation.success-title') as string,
          t('translation:general.operation.success') as string,
        );

        setIsOpen(false);
        onSuccessSubmit();

      } catch (error: any) {

        const messageKey = error.response && error.response.data && error.response.data.message;
        const message = t(`translation:${messageKey}`, t('translation:general.operation.fail')) as string;
        showNotification(
          t('translation:general.operation.fail-title') as string,
          message,
        );

        onFailSubmit(error);
      }
      finally {
        actions.setSubmitting(false);
      }
    },
  });

  // load initial data
  useEffect(() => {
    const orderStocks = (cartData && cartData.orderStocks || []);

    if (isOpen) {
      // @ts-ignore
      setInitialValues(prev => ({ ...prev, orderStocks: orderStocks }));
    }
  }, [isOpen, cartData]);

  useEffect(() => {
    // reset form after close
    if (!isOpen) {
      formik.resetForm();
    }
  }, [isOpen]);

  return (
    <>
      <OffCanvas
        setOpen={setIsOpen}
        isOpen={isOpen}
        titleId='task.verify'
        isBodyScroll
        placement='end'
        showBackdrop
        isBackdrop={false}
      >
        <OffCanvasHeader setOpen={setIsOpen}>
          <OffCanvasTitle id='task.verify'>{t('translation:task.verify') as ReactNode}</OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          <div className='row g-4'>

            {!loadingMenu &&
              <div className='col-12'>
                <FormGroup className="">
                  <div>

                    {
                      (menu && menu.items || []).map((item: any) => {
                        const { category, items } = item;

                        let cartItems: { stock: object, quantity: number }[] = [];

                        for (const stock of items) {
                          const orderStock = (formik.values.orderStocks || []).find((x: any) => x.idStock === stock.id);
                          if (orderStock) {
                            // @ts-ignore
                            const quantity = orderStock.quantity || 1;
                            cartItems.push({
                              stock: stock,
                              quantity: quantity,
                            })
                          }
                        }

                        if (!cartItems.length) {
                          return null;
                        }

                        return <div key={`cat-${category.id}`}>
                          <div className='text-primary'>{category.name}</div>

                          {cartItems.map((item: any, index: number) => (

                            <div key={`row-${index}`} className="mb-2 row">
                              <div className='col-6'>{item.stock.name}</div>
                              <div className='col-6'>
                                <Input
                                  type='number'
                                  value={item.quantity}
                                  onChange={(e: any) => {
                                    const value = e.target.value || 1;
                                    setQuantity(item.stock.id, Number(value));
                                  }}
                                  min={1}
                                  placeholder={t('translation:stock.orderStocks.quantity') as string}
                                  onFocus={() => {
                                    formik.setErrors({});
                                  }}
                                  onKeyPress={integerHandleKeyEvent}
                                  onPaste={integerHandlePasteEvent}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      })
                    }
                  </div>
                  {/*               
          {formik.errors.orderStocks && formik.touched.orderStocks && (
            <div className="invalid-feedback d-block">{formik.errors.orderStocks}</div>
          )} */}
                </FormGroup>
              </div>
            }

          </div>
        </OffCanvasBody>

        {!loadingMenu &&
          <div className='row m-0'>
            <div className='col-12 p-3'>
              <Button
                color='info'
                className='w-100'
                isDisable={formik.isSubmitting}
                onClick={() => {
                  formik.submitForm();
                }}>
                {formik.isSubmitting && (
                  <Spinner isSmall inButton isGrow />
                )}
                {t('translation:general.buttons.save') as ReactNode}
              </Button>
            </div>
          </div>
        }

      </OffCanvas>

      <ScreenLoader show={loadingMenu} />
    </>
  );
};

Cart.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),

  onSuccessSubmit: PropTypes.func,
  onFailSubmit: PropTypes.func,

  taskTypeOptions: PropTypes.array,
  employeeOptions: PropTypes.array,
  taskStatusTypeOptions: PropTypes.array,
};

Cart.defaultProps = {
  isOpen: false,
  setIsOpen: () => void 0,
  data: null,

  onSuccessSubmit: () => void 0,
  onFailSubmit: () => void 0,
};

export default Cart;
