export const UserPermission = {
    Authenticated: 0,

    PermissionManageStaff: 1,
    PermissionManageStock: 2,
    PermissionManageTasks: 3,
    PermissionManageMenu: 4,
    PermissionManageTables: 5,
    PermissionManagePayments: 6,
    PermissionManageTableDevices: 7,
    PermissionManageUsers: 8,
    PermissionManageOrders: 9,
    PermissionAssignTasks: 10,
    PermissionTherapistMonitoring: 11,
    PermissionManageUserProfile: 12,
    PermissionAutismClassification: 13,
    PermissionFaq: 14,
};

export const ProviderType = {
    Local: 'local',
    Google: 'google',
    Facebook: 'facebook',
};