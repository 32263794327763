import { appApi } from '../endpoint';

export function getAllOrderStatusType() {
  return appApi.get(`/api/v1/orderStatusType`);
}

export function getAllStockType() {
  return appApi.get(`/api/v1/stockType`);
}

export function getAllPaymentType() {
  return appApi.get(`/api/v1/paymentType`);
}

export function getAllPermission() {
  return appApi.get(`/api/v1/permission`);
}

export function getAllTabletType() {
  return appApi.get(`/api/v1/tabletType`);
}

export function getAllTaskStatusType() {
  return appApi.get(`/api/v1/taskStatusType`);
}

export function getAllUserProfileType() {
  return appApi.get(`/api/v1/userProfileType`);
}