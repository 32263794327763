import classNames from 'classnames';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { ReactNode, useContext, useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import QRCode from "react-qr-code";
import Select from 'react-select';
import * as nomenclatorApi from '../../../api/nomenclatorApi';
import * as orderApi from '../../../api/orderApi';
import { API_BASE } from '../../../common/config';
import { integerHandleKeyEvent, integerHandlePasteEvent } from '../../../common/form';
import { OrderStatusType } from '../../../common/orderStatusType';
import { PaymentType } from '../../../common/paymentType';
import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';
import Badge from '../../../components/bootstrap/Badge';
import Button from '../../../components/bootstrap/Button';
import ListGroup, { ListGroupItem } from '../../../components/bootstrap/ListGroup';
import OffCanvas, {
  OffCanvasBody,
  OffCanvasHeader,
  OffCanvasTitle
} from '../../../components/bootstrap/OffCanvas';
import Spinner from '../../../components/bootstrap/Spinner';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import showNotification from '../../../components/extras/showNotification';
import CartContext from '../../../contexts/cartContext';
import OrderCartContext from '../../../contexts/orderCartContext';
import ThemeContext, { VIEWMODE } from '../../../contexts/themeContext';
import { priceFormat } from '../../../helpers/helpers';
import useDarkMode from '../../../hooks/useDarkMode';
import useRemoteOptions from '../../../hooks/useRemoteOptions';

const Cart = (props: any) => {

  const { t } = useTranslation(['menu', 'translation']);
  const { themeStatus, darkModeStatus } = useDarkMode();

  const {
    isOpen,
    setIsOpen,
    data,
    onSuccessSubmit,
    onFailSubmit,
  } = props;

  const { cartData, setCartData } = useContext(CartContext);
  const {
    currentOrder,
    cancelOrder,
    cancelingOrder,
    loadCurrentOrder,
    total,
    setQuantity,
    deleteProduct,
    updating,
    qrPositions,
    getOrderStocksPosition,
    getPositionBgClassname,
    getNamePosition,
    verifyingPayment,
    verifyPayment,
    confirmingOrder,
    confirmOrder,
  } = useContext(OrderCartContext);

  // const [confirmingOrder, setConfirmingOrder] = useState(false);
  // const [payingOrder, setPayingOrder] = useState(false);
  // const [verifyingPayment, setVerifyingPayment] = useState(false);

  const { viewMode } = useContext(ThemeContext);

  const emptyInitialValues = {
    idOrderStatusType: 1,
    orderStocks: [],
  };
  const [initialValues, setInitialValues] = useState(emptyInitialValues);

  const emptyPayInitialValues = {
    idPaymentType: null,
  };
  const [payInitialValues, setPayInitialValues] = useState(emptyPayInitialValues);

  let showPaymentForm = cartData && cartData.idOrderStatusType === OrderStatusType.OrderStatusFinished || false;


  // @ts-ignore
  const [paymentTypeOptions, loadingPaymentTypesOptions] = useRemoteOptions({ requestData: (data && isOpen) ? () => nomenclatorApi.getAllPaymentType() : null, deps: [showPaymentForm, isOpen] });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validate: (values) => {
      const errors: {
        idOrderStatusType?: string;
        orderStocks?: string;
      } = {};

      if (!values.idOrderStatusType) {
        errors.idOrderStatusType = t('translation:general.validation.required-field') as string;
      }

      // if ((!values.orderStocks || !values.orderStocks.length)) {
      //   errors.orderStocks = t('translation:general.validation.required-field') as string;
      // } else {

      // }

      return errors;
    },
    validateOnChange: false,
    onSubmit: async (values, actions) => {

      try {

        actions.setSubmitting(true);
        await orderApi.createOrder({
          ...values,
          idOrderStatusType: 2,
          // id: cartData.id,
          // @ts-ignore
          orderStocks: (values.orderStocks || []).map(item => ({ ...item, quantity: Number(item.quantity) }))
        });

        showNotification(
          t('translation:general.operation.success-title') as string,
          t('translation:general.operation.success') as string,
        );

        setIsOpen(false);
        onSuccessSubmit();

      } catch (error: any) {

        const messageKey = error.response && error.response.data && error.response.data.message;
        const message = t(`translation:${messageKey}`, t('translation:general.operation.fail')) as string;
        showNotification(
          t('translation:general.operation.fail-title') as string,
          message,
        );

        onFailSubmit(error);
      }
      finally {
        actions.setSubmitting(false);
      }
    },
  });

  const formikPay = useFormik({
    enableReinitialize: true,
    initialValues: payInitialValues,
    validate: (values) => {
      const errors: {
        idPaymentType?: string;
      } = {};

      if (!values.idPaymentType) {
        errors.idPaymentType = t('translation:general.validation.required-field') as string;
      }

      return errors;
    },
    validateOnChange: false,
    onSubmit: async (values, actions) => {

      try {

        actions.setSubmitting(true);
        const payload = {
          idPaymentType: values.idPaymentType,
        };

        const response = await orderApi.payOrder(payload);
        await loadCurrentOrder();
        const { id, idOrderStatusType, orderStocks, orderStatusType, paymentInitPoint } = response.data.order;

        if (values.idPaymentType === PaymentType.PaymentManual) {
          setCartData({
            id: null,
            idOrderStatusType: OrderStatusType.OrderStatusStarted,
            orderStocks: [],
            orderStatusType: null,
            paymentInitPoint: null,
          });

          setIsOpen(false);

        }

        if (values.idPaymentType === PaymentType.PaymentMP) {
          setCartData({
            id: id || null,
            idOrderStatusType: idOrderStatusType || null,
            orderStocks: orderStocks || [],
            orderStatusType: orderStatusType || [],
            paymentInitPoint: paymentInitPoint || null,
          });
        }

      } catch (error: any) {

        const messageKey = error.response && error.response.data && error.response.data.message;
        const message = t(`translation:${messageKey}`, t('translation:general.operation.fail')) as string;
        showNotification(
          t('translation:general.operation.fail-title') as string,
          message,
        );

      }
      finally {
        actions.setSubmitting(false);
      }

    },
  });

  // load initial data
  useEffect(() => {
    if (isOpen) {
      loadCurrentOrder();
    }
  }, [isOpen]);

  useEffect(() => {
    // reset form after close
    if (!isOpen) {
      formik.resetForm();
    }
  }, [isOpen]);

  // const confirmOrder = async () => {
  //   try {

  //     if (!formik.values.orderStocks || !formik.values.orderStocks.length) {
  //       showNotification(
  //         t('translation:general.operation.fail-title') as string,
  //         t('translation:cart.emptyProducts') as string,
  //       );
  //       return;
  //     }

  //     setConfirmingOrder(true);

  //     const orderStatusPosition = (cartData.orderStatusPosition || []).reduce((acc: any, item: any) => {
  //       if (!acc.some((x: any) => x.position === item.position)) {
  //         const { id, idOrder, ...data } = item;
  //         acc.push({ ...data });
  //       }
  //       return acc;
  //     }, []);

  //     const payload = {
  //       idOrderStatusType: OrderStatusType.OrderStatusFinished,
  //       orderStocks: formik.values.orderStocks,
  //       orderStatusPosition: orderStatusPosition,
  //     };

  //     const response = await orderApi.upsertCurrentOrder(payload);
  //     const { id, idOrderStatusType, orderStatusType, orderStocks, paymentInitPoint } = response.data;

  //     setCartData((prev: any) => ({
  //       ...prev,
  //       id: id || null,
  //       idOrderStatusType: idOrderStatusType || null,
  //       orderStatusType: orderStatusType || null,
  //       orderStocks: orderStocks || [],
  //       paymentInitPoint: paymentInitPoint || null,
  //     }))

  //   } catch (error: any) {

  //     const messageKey = error.response && error.response.data && error.response.data.message;
  //     const message = t(`translation:${messageKey}`, t('translation:general.operation.fail')) as string;
  //     showNotification(
  //       t('translation:general.operation.fail-title') as string,
  //       message,
  //     );

  //   }
  //   finally {
  //     setConfirmingOrder(false);
  //   }
  // }

  // const verifyPayment = async () => {
  //   try {
  //     setVerifyingPayment(true);

  //     const payload = {
  //       paymentUrl: cartData.paymentInitPoint,
  //     };

  //     const response = await orderApi.verifyPayment(payload);
  //     const { completed } = response.data;

  //     if (completed) {
  //       showNotification(
  //         t('translation:general.operation.empty-title') as string,
  //         t('translation:cart.paymentSuccess') as string,
  //       );

  //       setCartData({
  //         id: null,
  //         idOrderStatusType: OrderStatusType.OrderStatusStarted,
  //         orderStocks: [],
  //         orderStatusType: null,
  //         paymentInitPoint: null,
  //       });

  //       setIsOpen(false);

  //     } else {
  //       showNotification(
  //         t('translation:general.operation.empty-title') as string,
  //         t('translation:cart.paymentPending') as string,
  //       );
  //     }

  //   } catch (error: any) {

  //     const messageKey = error.response && error.response.data && error.response.data.message;
  //     const message = t(`translation:${messageKey}`, t('translation:general.operation.fail')) as string;
  //     showNotification(
  //       t('translation:general.operation.fail-title') as string,
  //       message,
  //     );

  //   }
  //   finally {
  //     setVerifyingPayment(false);
  //   }
  // }

  const getStockName = (orderStock: any) => {
    // @ts-ignore
    const stock = orderStock && orderStock.stock || null;
    return stock ? stock.name : `ID: ${orderStock.idProduct} Producto no accesible`;
  }


  const renderStockPriceComponent = (orderStock: any) => {
    // @ts-ignore
    const stock = orderStock && orderStock.stock || null;

    if (!stock) {
      return null;
    }

    return (<div>
      {t('translation:cart.stock.price') as ReactNode}
      <Badge isLight color='primary'>
        {priceFormat(stock.amount)}
      </Badge>
    </div>)
  }

  return (
    <>
      <OffCanvas
        setOpen={setIsOpen}
        isOpen={isOpen}
        titleId='task.verify'
        isBodyScroll
        placement='end'
        showBackdrop
        isBackdrop={false}
      >
        <OffCanvasHeader setOpen={setIsOpen}>
          <OffCanvasTitle id='task.verify'>{t('translation:cart.order') as ReactNode}</OffCanvasTitle>
        </OffCanvasHeader>
        <OffCanvasBody>
          <div className='row g-4'>

            {(currentOrder?.orderStocks || []).length === 0 &&
              <div className='col-12'>
                <Alert color='secondary' shadow='md' isLight icon='Report'>
                  <AlertHeading>
                    {t('translation:cart.emptyProducts') as ReactNode}
                  </AlertHeading>
                  <p>
                    {t(`translation:cart.selectProducts`) as ReactNode}
                  </p>
                </Alert>
              </div>
            }

            {/* {currentOrder?.idOrderStatusType === OrderStatusType.OrderStatusFinished && */}
            {currentOrder?.idOrderStatusType === OrderStatusType.OrderStatusDelivered && viewMode !== VIEWMODE.TABLET_TABLE &&
              <>
                <div className='col-12'>
                  <FormGroup
                    id='idPaymentType'
                    label={t('translation:cart.paymentType') as string}
                  >
                    <Select
                      className="react-select form-control"
                      classNamePrefix="react-select"
                      placeholder={t('translation:general.placeholder.select') as string}
                      // @ts-ignore
                      options={paymentTypeOptions}
                      // @ts-ignore
                      value={(paymentTypeOptions || []).find((item: any) => formikPay.values.idPaymentType === item.id)}
                      onChange={(value: any) => {
                        formikPay.setFieldValue('idPaymentType', value && value.id || null);
                      }}
                      isClearable
                      closeMenuOnSelect
                    />

                  </FormGroup>
                  {/* @ts-ignore */}
                  {formikPay.errors.idPaymentType && formikPay.touched.idPaymentType && <div className="invalid-feedback d-block">{formikPay.errors.idPaymentType}</div>}

                </div>
              </>
            }

            {(currentOrder?.idOrderStatusType === OrderStatusType.OrderStatusReadyToPay && currentOrder?.paymentInitPoint) && viewMode !== VIEWMODE.TABLET_TABLE &&
              // {(currentOrder?.paymentInitPoint) &&
              <>
                <div className='col-12'>
                  <Alert color='info' shadow='md' isLight icon='Report'>
                    <AlertHeading>
                      {t('translation:cart.paymentProcess') as ReactNode}
                    </AlertHeading>
                    <p>
                      {t(`translation:cart.paymentQr`) as ReactNode}
                      <a className='ms-2' href={cartData.paymentInitPoint} target="_blank" rel="noopener noreferrer">
                        {t(`translation:cart.openLink`) as ReactNode}
                      </a>
                    </p>
                  </Alert>
                  <div className='text-center'>
                    <QRCode value={currentOrder?.paymentInitPoint || ''} />
                  </div>
                </div>
              </>
            }

            {/* {currentOrder?.idOrderStatusType === OrderStatusType.OrderStatusStarted && */}
            {(currentOrder?.idOrderStatusType !== OrderStatusType.OrderStatusDelivered &&
              currentOrder?.idOrderStatusType !== OrderStatusType.OrderStatusReadyToPay || (viewMode === VIEWMODE.TABLET_TABLE)) &&
              <>
                {(currentOrder?.orderStocks.length > 0) &&
                  <div className='col-12'>
                    <ListGroup>
                      {/* <ListGroupItem className='bg-info text-white'>
                        <div className='row'>
                          <div className='col-12'> {t('translation:cart.addedProductTitle') as ReactNode}</div>
                        </div>
                      </ListGroupItem> */}

                      <>
                        {(qrPositions || []).map((qrPosition: any) => (
                          <>
                            {(getOrderStocksPosition(qrPosition.position) as any[]).map((item: any, index: number) => (
                              <>

                                {index === 0 &&
                                  <ListGroupItem key={`header_${qrPosition.position}`} className={getPositionBgClassname(qrPosition.position)}>
                                    <div className='row'>
                                      <div className='col-12'>Posición {qrPosition.position} - {getNamePosition(qrPosition.position)}</div>
                                    </div>
                                  </ListGroupItem>
                                }

                                <ListGroupItem key={`${qrPosition.position}_${index}`}>

                                  <div className='row'>
                                    <div className='col-2'>
                                      {item?.stock?.imageS3Key &&
                                        <img className='w-100 img-fluid rounded-1' src={`${API_BASE}/api/v1/image/${item.stock.imageS3Key}`} alt='' />
                                      }
                                    </div>
                                    <div className='col-5'>
                                      <div>{getStockName(item)}</div>
                                      {/* <div> {t('translation:cart.stock.quantity') as ReactNode}: {item.quantity}</div> */}
                                      {renderStockPriceComponent(item)}
                                      <div>
                                        {t('translation:cart.stock.subtotal') as ReactNode}:
                                        <Badge isLight color='primary'>
                                          {priceFormat(item.stock.amount * item.quantity)}
                                        </Badge>
                                      </div>
                                    </div>
                                    {(viewMode !== VIEWMODE.TABLET_TABLE) &&
                                      <>
                                        <div className='col-3'>
                                          <Input
                                            size={'sm'}
                                            type='number'
                                            value={item.quantity}
                                            onChange={(e: any) => {
                                              const value = e.target.value || 1;
                                              setQuantity(item.tablePosition, item.idStock, Number(value));
                                            }}
                                            min={1}
                                            placeholder={t('translation:cart.stock.quantity') as string}
                                            onFocus={() => {
                                              formik.setErrors({});
                                            }}
                                            onKeyPress={integerHandleKeyEvent}
                                            onPaste={integerHandlePasteEvent}
                                            readOnly={updating}
                                          />
                                        </div>
                                        <div className='col-2'>
                                          <Button
                                            size={'sm'}
                                            isOutline={!darkModeStatus}
                                            color='dark'
                                            isLight={darkModeStatus}
                                            className={classNames('text-nowrap', {
                                              'border-light': !darkModeStatus,
                                            })}
                                            icon='Delete'
                                            onClick={async () => {
                                              deleteProduct(item.tablePosition, item.idStock)
                                            }}
                                          />
                                        </div>
                                      </>
                                    }
                                  </div>
                                </ListGroupItem>

                              </>
                            ))}
                          </>
                        ))}
                      </>

                      <ListGroupItem className='bg-info text-white'>
                        <div className='row'>
                          <div className='col-6'> {t('translation:cart.stock.total') as ReactNode}</div>
                          <div className='col-6 text-end'>
                            {priceFormat(total)}
                          </div>
                        </div>
                      </ListGroupItem>
                    </ListGroup>
                  </div>
                }
              </>
            }

          </div>
        </OffCanvasBody>


        {(viewMode !== VIEWMODE.TABLET_TABLE) &&
          <div className='row m-0'>
            <div className='col-12 p-3'>

              {(currentOrder?.idOrderStatusType === OrderStatusType.OrderStatusReadyToPay && currentOrder?.paymentInitPoint) &&
                <Button
                  color='info'
                  className='w-100 mb-1'
                  isDisable={confirmingOrder || updating || cancelingOrder || formikPay.isSubmitting || verifyingPayment}
                  onClick={async () => {
                    await verifyPayment();
                    setIsOpen(false);
                  }}>
                  {verifyingPayment && (
                    <Spinner isSmall inButton isGrow />
                  )}
                  {t('translation:cart.verifyPayment') as ReactNode}
                </Button>
              }

              {/* {currentOrder?.idOrderStatusType === OrderStatusType.OrderStatusFinished && */}
              {currentOrder?.idOrderStatusType === OrderStatusType.OrderStatusDelivered &&
                <Button
                  color='info'
                  className='w-100 mb-1'
                  isDisable={confirmingOrder || updating || cancelingOrder || formikPay.isSubmitting || verifyingPayment}
                  onClick={() => {
                    formikPay.submitForm();
                  }}>
                  {formikPay.isSubmitting && (
                    <Spinner isSmall inButton isGrow />
                  )}
                  {t('translation:cart.pay') as ReactNode}
                </Button>
              }

              {/* {currentOrder?.idOrderStatusType === OrderStatusType.OrderStatusStarted &&
              <>
                <Button
                  color='info'
                  className='w-100 mb-1'
                  isDisable={confirmingOrder || updating || cancelingOrder || formikPay.isSubmitting || verifyingPayment}
                  onClick={() => {
                    confirmOrder();
                  }}>
                  {confirmingOrder && (
                    <Spinner isSmall inButton isGrow />
                  )}
                  {t('translation:cart.confirmOrder') as ReactNode}
                </Button>
              </>
            } */}
              {(currentOrder?.idOrderStatusType === OrderStatusType.OrderStatusStarted && (currentOrder?.orderStocks || []).length > 0) &&
                <Button
                  color='info'
                  className='w-100 mb-1'
                  isDisable={confirmingOrder || updating || cancelingOrder || formikPay.isSubmitting || verifyingPayment}
                  onClick={() => {
                    cancelOrder();
                  }}>
                  {cancelingOrder && (
                    <Spinner isSmall inButton isGrow />
                  )}
                  {t('translation:cart.cancelOrder') as ReactNode}
                </Button>

              }

            </div>
          </div>
        }

      </OffCanvas>
    </>
  );
};

Cart.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ]),

  onSuccessSubmit: PropTypes.func,
  onFailSubmit: PropTypes.func,

  taskTypeOptions: PropTypes.array,
  employeeOptions: PropTypes.array,
  taskStatusTypeOptions: PropTypes.array,
};

Cart.defaultProps = {
  isOpen: false,
  setIsOpen: () => void 0,
  data: null,

  onSuccessSubmit: () => void 0,
  onFailSubmit: () => void 0,
};

export default Cart;
