import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ReactElement, forwardRef, useContext, useEffect, useLayoutEffect } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { LOGIN_TOKEN_KEY, TABLET_KEY } from '../../common/config';
import AuthContext from '../../contexts/authContext';
import { IPageProps } from '../Page/Page';
import { ISubHeaderProps } from '../SubHeader/SubHeader';

interface IPageWrapperProps {
	isProtected?: boolean;
	title?: string;
	description?: string;
	children:
	| ReactElement<ISubHeaderProps>[]
	| ReactElement<IPageProps>
	| ReactElement<IPageProps>[];
	className?: string;
}
const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
	({ isProtected, title, description, className, children }, ref) => {
		useLayoutEffect(() => {
			// @ts-ignore
			document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${process.env.REACT_APP_SITE_NAME
				}`;
			// @ts-ignore
			document
				?.querySelector('meta[name="description"]')
				.setAttribute('content', description || process.env.REACT_APP_META_DESC || '');
		});

		const location = useLocation();
		const isLoginWithQRPage = !!matchPath('/client/login/:qr', location.pathname);
		const isWelcomePage = !!matchPath('/client/welcome/:qr', location.pathname);

		const { logout } = useContext(AuthContext);
		const loginToken = localStorage.getItem(LOGIN_TOKEN_KEY) || '';
		const tabletData = localStorage.getItem(TABLET_KEY) || '';
		const forceLogout = !isLoginWithQRPage && !isWelcomePage && isProtected && (!loginToken && !tabletData);

		// console.log('--------');
		// console.log('isProtected:', isProtected);
		// console.log('loginToken:', loginToken);
		// console.log('tabletData:', tabletData);
		// console.log('isLoginWithQRPage:', isLoginWithQRPage);
		// console.log('forceLogout:', forceLogout);

		const navigate = useNavigate();
		useEffect(() => {
			if (forceLogout) {
				localStorage.clear();
				navigate(`../auth-pages/login`);
				// logout(); // todo: check
			}
			return () => { };
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return (
			<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
				{children}
			</div>
		);
	},
);
PageWrapper.displayName = 'PageWrapper';
PageWrapper.propTypes = {
	isProtected: PropTypes.bool,
	title: PropTypes.string,
	description: PropTypes.string,
	// @ts-ignore
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
PageWrapper.defaultProps = {
	isProtected: true,
	title: undefined,
	description: undefined,
	className: undefined,
};

export default PageWrapper;
