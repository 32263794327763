import React from 'react';
// import ReactDOM from 'react-dom'; // For React 17
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App/App';
import { AuthContextProvider } from './contexts/authContext';
import { CartContextProvider } from './contexts/cartContext';
import { OrderCartContextProvider } from './contexts/orderCartContext';
import { ThemeContextProvider } from './contexts/themeContext';
import './i18n';
import reportWebVitals from './reportWebVitals';
import './styles/styles.scss';
import 'devextreme/dist/css/dx.light.css';

const children = (

	<ThemeContextProvider>
		<Router>
			<AuthContextProvider>
				<OrderCartContextProvider>
					<CartContextProvider>
						<React.StrictMode>
							<App />
						</React.StrictMode>
					</CartContextProvider>
				</OrderCartContextProvider>
			</AuthContextProvider>
		</Router>
	</ThemeContextProvider>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
